import { createSelector } from 'reselect';
import { INITIAL_STATE } from './reducers';

const selectAccessDomain = state => state.access || INITIAL_STATE;

const makeSelectAccess = () =>
  createSelector(
    selectAccessDomain,
    access => access,
  ); 

export default makeSelectAccess;
export { selectAccessDomain };
