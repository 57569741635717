import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import makeSelectGlobal from "../../../redux/redux/global/selectors";

export default function StaffTrainingHoc(ComposedComponent) {
  class HOC extends Component {
    state = {
      yearOptions: [],
      selectedYear: "",
    };
    // list of year option
    setYearOpt = () => {
      let currentYear = new Date().getFullYear()
      const yearOpts = Array.from(Array(20)).map(item => ({
        text: currentYear,
        value: currentYear,
        ABC: --currentYear,
      }))
      this.setState((prevState) => ({
        ...prevState,
        yearOptions: [...yearOpts],
      }))
    }

    // set selected year option
    setSelectedYear = async (value) => {
      await this.setState((prevState) => ({
        ...prevState,
        selectedYear: value,
      }))
    }
    componentDidMount = () => {
      this.setYearOpt()
      }

    render() {
        return (
          <ComposedComponent
            {...this.props}
            staffData={this.state.staffData}
            yearOptions={this.state.yearOptions}
            selectedYear={this.state.selectedYear}
            setSelectedYear={this.setSelectedYear}
            />
          );
    }
  }
  const mapStateToProps = createStructuredSelector({
    global: makeSelectGlobal(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  return compose(withConnect, memo)(HOC);
}
