import React  from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Pagination } from "semantic-ui-react";
import { makeSelectDeviceDimension } from "../../redux/redux/device/selectors";

function Paginations({ dimension, setActivePage, activePage, totalPages, ...props }) {

  const renderPageName = () => {
    let data;
    if (!dimension.isMobile) {
      data = {
        nextItem: "Next",
        prevItem: "Previous",
      };
    }

    return data;
  };
  return (
    <Pagination
      floated="right"
      boundaryRange={0}
      // defaultActivePage={1}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      siblingRange={2}
      totalPages={totalPages}
      activePage={activePage}
      onPageChange={(e, data) => {
        setActivePage(data.activePage);
      }}
      {...renderPageName()}
      style={{
        gap: "10px",
        marginTop: "10px",
        marginBottom: "20px",
      }}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  dimension: makeSelectDeviceDimension(),
});
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
// const Paginations  =  connect(mapStateToProps, mapDispatchToProps)(Paginations)
export default connect(mapStateToProps, mapDispatchToProps)(Paginations);
// export const  Pagination ;
