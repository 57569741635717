import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import Spinners from "../../../components/Spinner/Spinners";
import makeSelectEvent from "../../../redux/redux/event/selectors";
import { makeSelectGlobal } from "../../../redux/redux/global/selectors";
import axios from "../../../utils/axios";
import { getCusLocalDateYear, getLocalTime } from "../../../utils/localTime";
import * as qs from "query-string";
import {
  addEventData,
  searchEvent,
  searchEventReset,
  setEventCount,
  setLoading,
  setEventPageNumber,
} from "../../../redux/redux/event/action";
import { toast } from "react-toastify";
import {
  closeModalAction,
  openModalAction,
} from "../../../redux/redux/modals/actions";
import { DELETE_CONFIRMATION } from "../../../utils/constant.utils";
import { withRouter } from "react-router";
// import { setAssignsTo, setCompanies } from "../../redux/redux/addEvent/action";
// import makeSelectAddEvent from "../../redux/redux/addEvent/selectors";

export default function EventHoc(ComposedComponent) {
     // event HOC component NOT in use
  class HOC extends Component {
    state = {
      loading: false,
      mounted: false,
    };
    onSelect = (value, type) => {
      this.props.dispatch(searchEvent(type, value));
    };
    resetSearch = () => {
      this.props.dispatch(searchEventReset());
    };
    getData = async (params = {}) => {
      await this.props.dispatch(setLoading(true));
      // await this.props.dispatch(setEventCount(0));
      // await this.props.dispatch(addEventData([]));
      // if (!this.state.mounted) {
      //   await this.setState({ loading: true, mounted: true });
      // }

      // this.setState({loading: true}, () =>{})
      axios
        .get("/marketing/event/fetchAllEvents", {
          params: {
            offset: this.props.event.pageNumber * 10 - 10,
            limit: 10,
            ...params,
          },
        })
        .then(async (result) => {
          let array = (await result.data.result.rows) || [];
          const count = (await result.data.result.count) || 0;
          array = array.map((item) => {
            item.createdAt = getLocalTime(item.createdAt);
            return item;
          });
          this.props.dispatch(addEventData([...array]));
          this.props.dispatch(setEventCount(count));
        })
        .catch(async (error) => {
          this.props.dispatch(addEventData([]));
          this.props.dispatch(setEventCount(0));
        })
        .finally(() => {
          this.props.dispatch(setLoading(false));
          this.setState({ loading: false });
        });
    };

    setEventPageNumber = async (pageNumber) => {
      await this.props.dispatch(setEventPageNumber(pageNumber));
      const query = await qs.stringify({ p: pageNumber });
      await this.props.history.push(this.props.location.pathname + `?${query}`);
      // await this.getData();
    };

    editRoute = (id) => {
      this.props.history.push(`/addEvents/update/${id}`);
    };

    deleteAction = (value, id) => {
      this.props.dispatch(closeModalAction());
      if (value === true) {
        this.deleteEvent(id);
      }
    };
    deleteEvent = (id) => {
      axios
        .delete(`/marketing/event/deleteEvent/${id}`)
        .then((res) => {
          if (res.status === 200)
          toast.success(`Event Deleted with Event Id: ${id}`, {
            toastId: "EventDeletedSuccessfully",
          });
        })
        .catch((e) => {})
        .finally(() => {
          this.getData();
        });
    };

    openDeleteAction = (id) => {
      this.props.dispatch(
        openModalAction({
          modalType: "DeleteAction",
          modalProps: {
            ...DELETE_CONFIRMATION,
            deleteFn: (value) => this.deleteAction(value, id),
            title: "Delete Event",
            message: "Are you sure you want to delete this Event?",
          },
        })
      );
    };
    renderLoading = () => {
      if (this.state.loading) return <Spinners />;
      return (
        <ComposedComponent
          {...this.props}
          setEventPageNumber={this.setEventPageNumber}
          deleteEvent={this.openDeleteAction}
          editRoute={this.editRoute}
          onSelect={this.onSelect}
          getData={this.getData}
        />
      );
    };
    componentDidMount() {
      // this.getStatus();
      // this.getManagers();
      // this.getEventType();
      // this.setState({loading:true})
      // this.setState({ loading: true }, () => {
      //   this.setEventPageNumbers(this.props.client.pageNumber);
      // });
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //   // const myProps = this.props !== nextProps;
    //   // const myState = this.state !== nextState;
    //   // return myProps || myState;
    // }

    render() {
      return <>{this.renderLoading()}</>;
    }
  }
  const mapStateToProps = createStructuredSelector({
    event: makeSelectEvent(),
    // addJob: makeSelectAddJob(),
    global: makeSelectGlobal(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(withConnect, memo, withRouter)(HOC);
}
