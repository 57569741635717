import produce from "immer";
import { SET_SWIPE_HISTORY, SET_YEAR_LIST } from "./constants";
export const INITIAL_STATE = {
  swipeYearList:[],
  swipeHistory:[]
};
const homeReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_YEAR_LIST:
        draft.swipeYearList = action.years;
        break;
      case SET_SWIPE_HISTORY:
        draft.swipeHistory = [...action.swipeHistory];
        break;
      default:
        return draft;
    }
  });
};

export default homeReducer;
