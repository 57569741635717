import produce from "immer";
import {
  ADD_TEAM,
  ADD_DESIGNATION,
  ADD_STAFF_ROLE,
  ADD_MANAGER,
  ADD_MARITAL_STATUS,
  ADD_BLOOD_GROUP,
  ADD_YEARLIST,
  ADD_FULL_PROFILE,
  RESET_TEAM,
  RESET_DESIGNATION,
  RESET_STAFF_ROLE,
  RESET_MANAGER,
  RESET_MARITAL_STATUS,
  RESET_BLOOD_GROUP,
  RESET_YEARLIST,
  RESET_FULL_PROFILE,
  ADD_DEPARTMENT,
  ADD_GRADE,
} from "./constants";
export const INITIAL_STATE = {
  team: [],
  designation: [],
  staffrole: [],
  manager: [],
  maritalstatus: [],
  bloodgroup: [],
  department: [],
  grade: [],
  yearlist: [],
  fullprofile: "",
};
const staffProfileReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_TEAM:
        draft.team = [...action.team];
        break
      case RESET_TEAM:
        draft.team = [];
        break
      case ADD_DESIGNATION:
        draft.designation = [...action.designation];
        break
      case RESET_DESIGNATION:
        draft.designation = [];
        break
      case ADD_STAFF_ROLE:
        draft.staffrole = [...action.staffrole];
        break
      case RESET_STAFF_ROLE:
        draft.staffrole = [];
        break
      case ADD_MANAGER:
        draft.manager = [...action.manager];
        break
      case ADD_FULL_PROFILE:
        draft.fullprofile = {...action.fullprofile};
        break
      case RESET_MANAGER:
        draft.manager = [];
        break
      case ADD_MARITAL_STATUS:
        draft.maritalstatus = [...action.maritalstatus];
        break
      case RESET_MARITAL_STATUS:
        draft.maritalstatus = [];
        break
      case ADD_BLOOD_GROUP:
        draft.bloodgroup = [...action.bloodgroup];
        break
      case ADD_DEPARTMENT:
        draft.department = action.department;
        break
      case ADD_GRADE:
        draft.grade = action.grade;
        break
      case RESET_BLOOD_GROUP:
        draft.bloodgroup = [];
        break
      case ADD_YEARLIST:
        draft.yearlist = [...action.yearlist];
        break
      case RESET_YEARLIST:
        draft.yearlist = [];
        break
      case RESET_FULL_PROFILE:
        draft.fullprofile = "";
        break
      default:
        return draft;
    }
  });
};

export default staffProfileReducer;
