import React from "react";
import StyleButton from "./EditorStyleButton";
// import rcEt from "../../styles/components/_editor.scss";
import "../../styles/components/_editor.scss";


const BlockStyleControls = ({ editorState, onToggle }) => {
  const BLOCK_TYPES = [
    { label: "indent", style: "blockquote" },
    { label: "list ul", style: "unordered-list-item" },
    { label: "list ol", style: "ordered-list-item" },
    { label: "Code Block", style: "code-block" },
  ];

  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
  return (
    <div className={'RichEditor-controls'}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
export default BlockStyleControls;
