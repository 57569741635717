import moment from "moment";
import React, { useState, useEffect } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Button, Form, Grid, Item, Progress, Segment } from "semantic-ui-react";
import { isDate } from "../../../../utils/validator";
import axios from "../../../../utils/axios";
import { getCusLocalDateYear } from "../../../../utils/localTime";
import { toast } from "react-toastify";
function Trial(trialId) {
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [trialDataId, setTrialDataId] = useState(trialId);
  const [trialValue, setTrialValue] = useState({
    startDate: "",
    endDate: "",
  });
  const [trialValueError, setTrialValueError] = useState({
    startDateError: "",
    endDateError: "",
  });
  const getTrialById = () => {
    axios
      .get(`/marketing/enquiry/fetchEnquiryById?id=${trialDataId.trialId}`)
      .then(async (res) => {
        let data = await { ...res.data.result };
        let updateData = await {
          startDate: getCusLocalDateYear(data.trail_start_date || ""),
          endDate: getCusLocalDateYear(data.trail_end_date || ""),
        };
        setTrialValue((prevState) => updateData);
        let currentDate = getCusLocalDateYear(moment());
        // let diff = data.trail_start_date - dd;
        let end = getCusLocalDateYear(data.trail_end_date || "")
        let start = getCusLocalDateYear(data.trail_start_date || "")
        if(currentDate < start){
          alert("big")
          setPercent(0);
        }else if(currentDate > start){
          const totalDays = moment(data.trail_end_date || "").diff(moment(data.trail_start_date || ""), "days");
          const currentDiff = moment(data.trail_end_date || "").diff(moment(), "days");
          let percentTotal = (((totalDays - currentDiff) / (totalDays)) * 100);
          // alert(Math.round(percentTotal));
          setPercent(Math.round(percentTotal))
        }
        
        // alert(diffInDays);
      })  
      .catch((res) => {
        setTrialValue({
          startDate: "",
          endDate: "",
        });
      })
      .finally(() => {});
  };
  const onValidator = (e) => {
    switch (e.target.name) {
      case "startDate":
        return isDate(e.target.value, "Start Date");
      case "endDate":
        return isDate(e.target.value, "End Date");
      default:
        return null;
    }
  };
  useEffect(() => {
    getTrialById();
    // var start = moment(trialValue.startDate);
    // var end = moment(trialValue.endDate);
    // alert(end.diff(start, "days"));
  }, []);
  const validate = () => {
    let isError = false;
    Object.values({ ...trialValue }).forEach((value) => {
      if (!value) {
        isError = true;
      }
    });
    return isError;
  };
  const onSubmitValidate = async () => {
    let data = { ...trialValue };
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };
      await setTrialValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };
  const onChange = (e) => {
    let newValues = { ...trialValue, [e.target.name]: e.target.value };
    let errorData = {
      ...trialValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    setTrialValue({ ...newValues });
    setTrialValueError({ ...errorData });
  };
  const onSubmit = async () => {
    let isError = await validate();
    if (!isError) {
      setLoading(true);
      axios
        .put(
          `/marketing/enquiry/editTrialDate?id=${trialDataId.trialId}&trailStart=${trialValue.startDate}&trailEnd=${trialValue.endDate}`
        )
        .then((res) => {
          if (res.status === 200)
            toast.success(`Trial Date Updated Successfully`, {
              toastId: "clientUpdatedSuccessfully",
            });
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response.data.message ||
              "Something went wrong, Please try again later",
            {
              toastId: "clientAddedError",
            }
          );
        })
        .finally(() => {
          // if (formValue.category === 2) {
          // handleClick("next");
          // } else {
          //   props.history.replace("/enquiryList");
          // }
          getTrialById();
          setLoading(false);
        });
    } else {
      toast.error("Please enter all mandatory fields", {
        toastId: "JobInvalidData",
      });
      onSubmitValidate();
    }
  };
  return (
    <Grid.Column
      mobile={16}
      tablet={16}
      computer={16}
      className="topBottomMarginNone"
    >
      <div>
        <Segment raised className="chart-container-height">
          <div className="formContainer">
            <div className="progressHeading">Trial Start Date</div>
            <Form className="fontQuicksand">
              <Form.Group widths={16} className="formGroup marginBottomNone">
                <DateInput
                  label="START DATE"
                  popupPosition="bottom"
                  className={"dateInput topMarginInput inlineLabel"}
                  icon={"calendar outline pointer large"}
                  name="startDate"
                  closeOnMouseLeave
                  placeholder="Start Date"
                  value={trialValue.startDate}
                  iconPosition="right"
                  width={5}
                  closable
                  dateFormat={"DD-MM-YYYY"}
                  hideMobileKeyboard
                  animation={"drop"}
                  clearable
                  onChange={(event, { name, value }) => {
                    event.target.value = value;
                    event.target.name = "startDate";
                    onChange(event);
                  }}
                  error={
                    trialValueError.startDateError
                      ? trialValueError.startDateError
                      : null
                  }
                  // minDate={moment()}
                />

                <DateInput
                  label="END DATE"
                  popupPosition="bottom"
                  className={"dateInput topMarginInput inlineLabel"}
                  icon={"calendar outline pointer large"}
                  name="endDate"
                  closeOnMouseLeave
                  placeholder="End Date"
                  value={trialValue.endDate}
                  iconPosition="right"
                  width={5}
                  closable
                  dateFormat={"DD-MM-YYYY"}
                  hideMobileKeyboard
                  animation={"drop"}
                  clearable
                  onChange={(event, { name, value }) => {
                    event.target.value = value;
                    event.target.name = "endDate";
                    onChange(event);
                  }}
                  error={
                    trialValueError.endDateError
                      ? trialValueError.endDateError
                      : null
                  }
                  // minDate={moment()}
                />
                <div className="buttonDiv topMarginInput">
                  <Button
                    type="submit"
                    className="dotButtonEnquiry"
                    onClick={onSubmit}
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                    {/* {props.match && props.match.type && props.match.id
                      ? "Update"
                      : "Submit"} */}
                  </Button>
                </div>
                <div className="buttonDiv topMarginInput">
                  <Button
                    type="submit"
                    className="dotButtonEnquiryInverted"
                    //   onClick={onSubmit}
                    //   loading={loadingOnSubmit}
                    //   disabled={loadingOnSubmit}
                  >
                    End Trail
                    {/* {props.match && props.match.type && props.match.id
                      ? "Update"
                      : "Submit"} */}
                  </Button>
                </div>
              </Form.Group>
            </Form>

            <Grid className="topMarginInput">
              <Grid.Row stretched>
                <Grid.Column>
                  <Item className="progressBarContainer">
                    <div className="progressHeading">Trial Progress</div>
                    <Progress percent={percent} size="small" color="orange" />
                    <div className="labelPositionLeft">
                      {trialValue.startDate}
                    </div>
                    <div className="labelPositionRight">
                      {trialValue.endDate}
                    </div>
                  </Item>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>
      </div>
    </Grid.Column>
  );
}

export default Trial;
