export const SET_ERROR = "@staff/SET_ERROR";
export const RESET_ERROR = "@staff/RESET_ERROR";
export const SET_ERROR_MESSAGE = "@staff/SET_ERROR_MESSAGE";
export const SET_STAFF = "@staff/SET_STAFF";
export const SET_STAFF_COUNT = "@staff/SET_STAFF_COUNT";
export const SET_PAGE_NO = "@staff/SET_PAGE_NO";
export const RESET_PAGE_NO = "@staff/RESET_PAGE_NO";
export const SET_LOADING = "@staff/SET_LOADING";
export const SET_LOADING_STAFF = "@staff/SET_LOADING_STAFF";
// export const SET_ERROR_STAFF = "@staff/SET_ERROR_STAFF";
// export const SET_ERROR_STAFF_RESET = "@staff/SET_ERROR_STAFF_RESET";

export const STAFF_SEARCH = "@staff/STAFF_SEARCH";
export const STAFF_ROLES = "@staff/STAFF_ROLES";
export const STAFF_SEARCH_RESET = "@staff/STAFF_SEARCH_RESET";
