import React, { useEffect, useState } from "react";
// import { TimelineLite } from "gsap";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2, gsap } from "gsap";
import OtpInput from "react-otp-input";
import axios from "../../utils/axios";
gsap.registerPlugin([TimelineLite]);
export default function CodeVerification(props) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  // const [formValue, setFormValue] = useState({
  //   // role: "",
  //   email: props.email,
  //   // token: "",
  // });
  useEffect(() => {
    let recovery = new TimelineLite({ paused: true, smoothChildTiming: true, reversed: true, yoyo: true });
    recovery
      .fromTo(
        "#code_verification",
        { opacity: 0, ease: Power2.easeInOut, y: -50 },
        { opacity: 1, ease: Power2.easeInOut, y: 0 }
      )
      .play();
    return () => { };
  }, []);

  const getCodeBoxElement = (index) => {
    return document.getElementById("codeBox" + index);
  };
  const onKeyUpEvent = (index, event) => {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 6) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  };
  const onFocusEvent = (index) => {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  };

  const loginTypeValue = () => {
    if (props.loginType === "DOT_TEAM") {
      return { role: 4 };
    }
    return {};
  };

  const validate = () => {
    if (isNaN(otp)) {
      setOtpError("Please enter a valid OTP")
    }
    else if (otp == '') {
      setOtpError('Please enter OTP')
    }
    else {
      let value = { email: props.email, token: otp, ...loginTypeValue() };
      axios
        .post("/forgetPassword/verifyToken", value, {
          headers: {},
        })
        .then((response) => {
          if (response.status === 200) {
            setEmailSuccess(response.data.message)
            props.onNext()
            props.otp(otp)
          }
          else {
            setOtpError(response.data.message)
          }
        })
        .catch((response) => {
          setOtpError("Invalid Token");
        })
        .finally(() => {
        });
    }
  }

  const onOtpChange = (e) => {
    setOtp(e);
    setOtpError('')
  };
  return (
    <div className="client-login recovery" id="code_verification">
      <p className="login-input-header">Code Verification</p>
      <p className="forgot-password-description">Enter Code sent to your Email address </p>
      <div className="input-form-control" name='formOtp' style={{ width: "auto" }}>

        <label className="input-form-control-label" htmlFor="otp">
          Code
        </label>
        <OtpInput
          value={otp}
          onChange={onOtpChange}
          numInputs={6}
          // separator={}
          shouldAutoFocus
          inputStyle={"inputStyle"}
          id={"otp"}
        />
        <div className='errorLabel'>
          {otpError}
        </div>
        <div className='successLabel'>
          {emailSuccess}
        </div>
      </div>

      <button
        className="login-type-submit"
        style={{
          height: window.innerHeight / 12,
          width: window.innerHeight / 12,
        }}
        onClick={
          validate
        }
      >
        <Icon name={"arrow right"} size="large" />
      </button>
    </div>
  );
}
