import React, { useState } from "react";

import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Form, Grid, Select } from "semantic-ui-react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { makeSelectDeviceDimension } from "../../../redux/redux/device/selectors";
import {
  resetAddAttendancetype,
  setAddAttendancetype,
} from "../../../redux/redux/masters/action";
import makeSelectMaster from "../../../redux/redux/masters/selectors";
import { closeModalAction } from "../../../redux/redux/modals/actions";
import { ADD, ADD_ATTENDANCE_TYPE, ATTENDANCE_CODE, ATTENDANCE_TYPE, CANCEL, EDIT, UPDATE } from "../../../utils/labelContant";
function AddAttendancetype(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <ModalWrapper
      size="mini"
      header={props.header || ADD_ATTENDANCE_TYPE}
      esc
      scrolling
      className={"add"}
      closeModal={props.closeModal}
    >
      <Form.Group className="">
        <Form.Input
          className=""
          label={ATTENDANCE_TYPE}
          placeholder={ATTENDANCE_TYPE}
          // width={16}
          name="Attendancetype"
          value={props.master.addAttendancetype.Attendancetype}
          onChange={(e) => dispatch(setAddAttendancetype(e))}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100% !important",
          }}
        />
        <Form.Input
          className=""
          label={ATTENDANCE_CODE}
          placeholder={ATTENDANCE_CODE}
          // width={16}
          name="Attendancecode"
          value={props.master.addAttendancetype.Attendancecode}
          onChange={(e) => dispatch(setAddAttendancetype(e))}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100% !important",
          }}
        />
      </Form.Group>

      <Grid
        columns="2"
        centered
        verticalAlign="middle"
        style={{ marginTop: "20px" }}
      >
        <Grid.Column>
          <Button
            style={{ width: "100%" }}
            className="secondary"
            onClick={() => {
              setLoading(true);
              props.onSubmit(props.master.addAttendancetype);
            }}
            loading={loading}
            disabled={loading}
          >
            {props.type && props.type === EDIT ? UPDATE : ADD}
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="red"
            style={{ width: "100%" }}
            onClick={() => {
              dispatch(resetAddAttendancetype());
              dispatch(closeModalAction());
            }}
            disabled={loading}
          >
            {CANCEL}
          </Button>
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  dimension: makeSelectDeviceDimension(),
  master: makeSelectMaster(),
});

export default connect(mapStateToProps, null)(AddAttendancetype);
