import produce from "immer";
export const INITIAL_STATE = {
  
};
const loginReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      default:
        return draft;
    }
  });
};

export default loginReducer;
