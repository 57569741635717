import React, {useEffect, useState, useCallback   } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Menu } from "semantic-ui-react";
import ContainerTitle from "../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../components/ContainerTitle/TitleWrapper";
import CustomTable from "../../components/CustomTable/CustomTable";
import { store } from "../../redux/store";
import AccessCheck from "../../utils/AccessCheck";
import { accessActionType, accessModule } from "../../utils/accessConstant";
import axios from "../../utils/axios";
import { CSVLink } from "react-csv";
import { Button, Icon } from "semantic-ui-react";
import encryptData from "../../utils/crypto";
import {
  ACTUAL,
  BUDGET,
  CATCH_TOAST,
  CLIENT,
  CLIENT_ADDED_ERROR,
  CLIENT_NAME,
  CNAME,
  COMPANY,
  COMPANY_NAME,
  COMPANY_NAMES,
  COMPLETED,
  CREATED_BY,
  CREATE_BY,
  DAYS,
  DAYS_LEFT,
  Day_Left,
  DEADLINE_DATE,
  DISPATCH_DATE,
  DISPATCH_DATE_ID,
  EXPORT,
  ID,
  JOBID,
  jobTat,
  JOBTYPE,
  JOB_CREATION_DATE,
  JOB_END_DATE,
  JOB_MANAGER_DOT,
  JOB_REFERENCE_NO,
  JOB_START_DATE,
  JOB_STATUS_PERC,
  JOB_TAT,
  JOB_TITLE_ID,
  JOB_TYPE_ID,
  JOB_TYPE_TITLE,
  MANAGER,
  NOT_APPLICABLE,
  NOT_APPLICABLE_DATE,
  OVERDUE,
  RECURRENCE_JOBS,
  REFERENCE_NO,
  REF_NO,
  RESET,
  STATUS,
  STATUS_ID,
  TAT,
  YEAREND,
  YEAR_END_DATE,
  D_LEFT, 
  JOB_MANAGER,
  RECURRENCE_HEADERS

} from "../../utils/labelContant";
import { getCusLocalDate, getCusLocalDateYear } from "../../utils/localTime";
import RecurrenceJobHoc from "./RecurrenceJobHOC";
import moment from "moment";
import { loopObject } from "../../utils/objectLoop";
import { percentAsPerStatus } from "../../utils/percAsPerStatus";

const RecurrenceJob = ({
  recurrence,
  onSelect,
  resetSearch,
  getData,
  setPageNumber,
  ...props }) => {
  const [exportData, setExportData] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const countExp=0;
  const history = useHistory();
  const getExportData = () => {
    axios
    .get(`jobs/fetchRecursionJob`)
    .then(async(res) => {
        let data = res.data.result[0].rows;
       data = data.map((item) => {
        let newVal = {
          ...item,

          id:item?.id || NOT_APPLICABLE ,
          reference_no: item?.reference_no || NOT_APPLICABLE,
          company: item?.jobSowDoc?.company_name || "",
          client: item?.Company?.company_name || "",
          job_status_perc : percentAsPerStatus(item?.Status?.status),
          job_type: item?.Job_type?.job_name || NOT_APPLICABLE,
          yearend: item?.jobSowDoc?.year_end_date === NOT_APPLICABLE_DATE 
            ? NOT_APPLICABLE
            : getCusLocalDateYear(item?.jobSowDoc?.year_end_date),
            job_status: item?.Status?.status,
            createdBy: item?.createdByName || "",
            budget: item?.Quotation?.total_budget_hours || "",
            actual: item?.Quotation?.total_actual_hours || "",
            CREATE_BY: item?.createdByName || "",
          job_start_date:
            item?.job_start_date === NOT_APPLICABLE_DATE ? NOT_APPLICABLE: getCusLocalDateYear(item?.job_start_date),
        job_end_date:
            item?.job_end_date === NOT_APPLICABLE_DATE ? NOT_APPLICABLE: getCusLocalDateYear(item?.job_end_date),
        dispatch_date:
                item?.dispatch_date === NOT_APPLICABLE_DATE  ? NOT_APPLICABLE : getCusLocalDateYear(item?.dispatch_date),
        days_left:  (item?.daysLeft == null ? "" : (item?.daysLeft == -1 || -item?.daysLeft ? OVERDUE : `${item?.daysLeft} ${DAYS}`)),
        manager: item?.Manager?.employee_name,
        recursion_status:item?.recursion_status

        }
        return newVal;
      })
      setExportData([...data]);
    })
      .catch((error) => {
        toast.error(
          CATCH_TOAST,
          {
            toastId: CLIENT_ADDED_ERROR,
          }
        );
    })   
}  
  const checkSearch = useCallback(() => {
    let search = false;
    Object.values({ ...recurrence.recurrenceSearch }).forEach((value) => {
      if (value) {
        search = true; 
      }
    });
    return search;
  }, [recurrence.recurrenceSearch]);

  const getRecurrenceList = useCallback(
    (searchObject) => {
      getData({ ...searchObject });
    }, [getData]);

  useEffect(() => {
    getRecurrenceList(loopObject(recurrence.recurrenceSearch));
    setShowReset(checkSearch());
    getExportData()
  }, [recurrence.pageNumber, recurrence.recurrenceSearch, getRecurrenceList, checkSearch]);

  return (
    <div className="container-height-width">
      <TitleWrapper>
        <ContainerTitle title={RECURRENCE_JOBS} />
        <div>
        {showReset &&(
              <Button
                className="dotButton"
                basic
                floated="right"
                content={RESET}
                color="red"
                onClick={resetSearch}
              />
            )}
          <CSVLink
            className={exportData.length === 0 ? "disabledForce" : ""}
            data={exportData}
            headers={RECURRENCE_HEADERS}
            filename={`${RECURRENCE_JOBS} ${getCusLocalDate(moment())}.csv`}
          >
            <Button
              icon
              labelPosition="right"
              basic
              color="red"
              loading={exportData.length === 0}
              disabled={exportData.length === 0}
              className={
                exportData.length === 0
                  ? `dotButtonDisabled ${countExp > 10 ? "bt-32" : ""}`
                  : `dotButton ${countExp > 10 ? "bt-32" : ""}`
              }
            >
              {EXPORT}
              <Icon name="file excel" />
            </Button>
          </CSVLink>
        </div>
      </TitleWrapper>

      <div className="customTableWraper">
        <CustomTable
          cols={[
            {
              id: ID,
              title: JOB_TITLE_ID,
              fixed: true,
              search: true,
              onSelect: onSelect,
              type: JOBID,
              text: recurrence.recurrenceSearch.jobId || "",
              className: "first-col",
              options: [],
            },
            {
              id: REFERENCE_NO,
              title: JOB_REFERENCE_NO,
              search: true,
              onSelect: onSelect,
              type: REF_NO,
              text: recurrence.recurrenceSearch.refNo || "",
              options: [],
            },
            {
              id: COMPANY,
              title: COMPANY_NAME,
              classStyle: {},
              search: true,
              onSelect: onSelect,
              type: CNAME,
              text: recurrence.recurrenceSearch.cname || "",
              options: [],
            },
            {
              id: CLIENT.toLowerCase(),
              title: CLIENT_NAME,
              onSelect: onSelect,
              search: true,
              type: COMPANY_NAMES,
              text: recurrence.recurrenceSearch.company_name || "",
              options: [],

            },
            {
              id: JOB_STATUS_PERC,
              title: COMPLETED,
            },
            {
              id: JOB_TYPE_ID,
              title: JOB_TYPE_TITLE,
              classStyle: {},
              search: true,
              onSelect: onSelect,
              type: JOBTYPE,
              text: recurrence.recurrenceSearch.jobType || "",
              options: [...props.job_type],
              
            },
            {
              id: YEAREND.toLowerCase(),
              title: YEAR_END_DATE,
              classStyle: {},
            },
            {
              id: JOB_STATUS_PERC.slice(0, 10),
              title: STATUS,
              search: true,
              onSelect: onSelect,
              options: [...props.jobStatus],
              text: recurrence.recurrenceSearch.status || "",
              type: STATUS_ID,
            },
            {
              id: BUDGET?.toLowerCase(),
              title: BUDGET,
            },
            {
             id: ACTUAL?.toLowerCase(),
              title: ACTUAL,
            },
            {
              id: CREATED_BY,
              title: CREATE_BY,
            },
            {
              id: JOB_START_DATE,
              title: JOB_CREATION_DATE,
            },
            {
              id: JOB_END_DATE,
              title: DEADLINE_DATE,
            },
            {
              id: DISPATCH_DATE,
              title: DISPATCH_DATE_ID,
            },
            {
              id: JOB_TAT,
              title: TAT,
              search: true,
              type: JOB_TAT,
              onSelect: onSelect,
              options: [...jobTat],
              text: recurrence.recurrenceSearch.job_tat || "",
            },
            {
              id: DAYS_LEFT,
              title: Day_Left,
              search: true,
              onSelect: onSelect,
              options: [...jobTat],
              text: recurrence.recurrenceSearch.daysleft || "",
              type: D_LEFT 
            },
            {
              id: MANAGER,
              title: JOB_MANAGER_DOT,
              search: true,
              type: JOB_MANAGER,
              onSelect: onSelect,
              options: [...props.job_Manager],
              text: recurrence.recurrenceSearch.jobManager || "",
            },
          ]}
          data={recurrence?.recurrence}
          count={recurrence?.count}
          setPagination={(pageNumber) => setPageNumber(pageNumber)}
          activePage={recurrence?.pageNumber}
          renderMenuItem={(row) => {
            return (
              <Menu fluid vertical tabular className="user-menu">
                {AccessCheck(
                  accessModule.RECURRENCE_JOBS,
                  accessActionType.UPDATE,
                  store.getState().global?.access
                ) && (
                  <Menu.Item
                    name="Details"
                    onClick={() =>
                      history.push(
                        `/jobDetails/${encryptData(row.id.toString(), "enc")}`
                      )
                    }
                  />
                )}
              </Menu>
            );
          }}
        />
      </div>
    </div>
  );
};

export default RecurrenceJobHoc(RecurrenceJob);