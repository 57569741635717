import { isDate } from "lodash";
import { useEffect, useState } from "react";
import ReactPlayer from 'react-player/lazy'
import Dropzone, { useDropzone } from "react-dropzone";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { toast } from "react-toastify";
import axios from "../../../utils/axios"
import { DateInput } from "semantic-ui-calendar-react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Segment,
  Select,
} from "semantic-ui-react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import { stringType } from "../../../utils/validator";
import { useCallback } from 'react';
import moment from "moment";
import { store } from "../../../redux/store";
import { useHistory } from "react-router-dom";


const ViewDotSkills = (props) => {
  const { location } = props;
  useEffect(() => {
  }, []);
  let linkData = location?.viewData?.link.split(':::') || "";
  let categorySplit = location?.viewData?.category.split('-');
  return (
    <>
      <div className="container-height-width">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Segment style={{ borderRadius: "10px" }}>
            <div className="">
              <TitleWrapper>
                <div className="cusTitle">
                  <ContainerTitle title={categorySplit[0] || "Category"} />
                  <p className={"subsection"}>{"> " + location?.viewData?.training_topic || "Topic"}</p>
                </div>
              </TitleWrapper>
            </div>
            <div className="attendanceTopInputsContainer">
              {linkData[1] === "VIDEO/AUDIO" ? (<ReactPlayer playing={true} controls={true} url={linkData[0] || "#"} />)
                : (<a href={linkData[0] || "#"} target="_blank">{linkData[0] || "#"}</a>)}
            </div>
          </Segment>
        </Grid.Column>
      </div>
    </>
  );
};
export default ViewDotSkills;
