// import { useEffect } from "react";
import { store } from "../redux/store";

let Key = store.getState().global || "";

export const CommonAccessCheck = (module, access = [], action = "READ") => {
  let abc = access.filter(
    (e) =>
      e.access_details?.module?.name.includes(module) &&
      e.access_details?.status == action
  );
  if (abc.length > 0) {
    return true;
  } else {
    return false;
  }
};

const AccessCheck = (module, action, access = []) => {
  // access check function common in every module to check access
  let abc = access.filter(
    (e) =>
      e.access_details?.module?.name == module &&
      e.access_details?.status == action
  );
  if (abc.length > 0) {
    return true;
  } else {
    return false;
  }
  // return true;
};

export default AccessCheck;
