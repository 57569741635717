import { capitalize, lowerCase, upperCase } from "lodash";


export function capital (value){
    return capitalize(value)
}
export function upper_Case (value){
    return upperCase(value)
}
export function lower_Case (value){
    return lowerCase(value)
}