import { RECURRENCE_SEARCH, RECURRENCE_SEARCH_RESET, SET_PAGE_NO, SET_RECURRENCE, SET_RECURRENCE_COUNT  } from "./constants";

export const searchRecurrence = (name, value) => {
  return {
    type: RECURRENCE_SEARCH,
    name,value
  };
};
export const searchRecurrenceReset = () => {
  return {
    type: RECURRENCE_SEARCH_RESET,
   
  };
};
export const setPageNo = (pageNumber) => ({
  type: SET_PAGE_NO,
  pageNumber,
});
export const setRecurrence = (recurrence) => ({
  type: SET_RECURRENCE,
  recurrence,
});
export const setTotalCount = (count) => ({
  type: SET_RECURRENCE_COUNT,
  count,
});

