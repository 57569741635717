import produce from "immer";
import {
  RECURRENCE_SEARCH_RESET,
  RECURRENCE_SEARCH,
  SET_PAGE_NO,
  SET_RECURRENCE,
  SET_RECURRENCE_COUNT,
} from "./constants";
export const INITIAL_STATE = {
  pageNumber: 1,
  recurrenceSearch: [],
  recurrence: [],
  count: 0,
};
const recurrenceReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case RECURRENCE_SEARCH:
        const data = { ...draft.recurrenceSearch, [action.name]: action.value };
        draft.recurrenceSearch = data;
        break;
      case RECURRENCE_SEARCH_RESET:
        draft.recurrenceSearch = {};
        break;
      case SET_PAGE_NO:
        draft.pageNumber = action?.pageNumber;
        break;
      case SET_RECURRENCE:
        draft.recurrence = action?.recurrence;
        break;
        case SET_RECURRENCE_COUNT:
          draft.count = action?.count;
          break;
      
      default:
        return draft;
    }
  });
};

export default recurrenceReducer;
