import { createSelector } from "reselect";
import { INITIAL_STATE } from "./reducers";

/**
 * Direct selector to the home state domain
 */

const selectMasterDomain = (state) => state.master || INITIAL_STATE;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Home
 */

const makeSelectMaster = () => createSelector(selectMasterDomain, (master) => master);

export default makeSelectMaster;
export { selectMasterDomain, makeSelectMaster };
