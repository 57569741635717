export const ENQUIRY_FETCH = "@enquiry/ENQUIRY_FETCH";
export const ENQUIRY_SUCCESS = "@enquiry/ENQUIRY_SUCCESS";
export const ENQUIRY_ERROR = "@enquiry/ENQUIRY_ERROR";




export const ENQUIRY_GET = "@enquiry/ENQUIRY_GET";
export const ENQUIRY_GET_ERROR = "@enquiry/ENQUIRY_GET_ERROR";
export const ENQUIRY_GET_LIST_LOADING = "@enquiry/ENQUIRY_GET_LIST_LOADING";


export const ENQUIRY_GET_PAGE_NO= "@enquiry/ENQUIRY_GET_PAGE_NO";
export const ENQUIRY_COUNT= "@enquiry/ENQUIRY_COUNT";
export const ENQUIRY_SEARCH= "@enquiry/ENQUIRY_SEARCH";
export const ENQUIRY_SEARCH_RESET= "@enquiry/ENQUIRY_SEARCH_RESET";
export const ADD_SOURCE= "@enquiry/ADD_SOURCE";
export const ADD_EVENT= "@enquiry/ADD_EVENT";
export const ADD_ENGAGEMENT= "@enquiry/ADD_ENGAGEMENT";
export const ADD_CATEGORISATION= "@enquiry/ADD_CATEGORISATION";
