import React, { useEffect, useState } from "react";
import {
  Form,
  Grid,
  Button,
  Segment,
  Input,
  Select,
  Item,
  Header,
  Icon,
} from "semantic-ui-react";
import axios from "../../utils/axios";
import { Helmet } from "react-helmet";
import TitleWrapper from "../../components/ContainerTitle/TitleWrapper";
import ContainerTitle from "../../components/ContainerTitle/ContainerTitle";
import {
  ATTACH_DOCUMENT,
  CANDIDATE,
  CANDIDATE_DESIGNATION,
  CANDIDATE_DESIGNATION_TITLE,
  CANDIDATE_ID,
  CANDIDATE_ID_TITLE,
  CANDIDATE_TOAST,
  CATCH_TOAST,
  COMPANY_NAME,
  COMPANY_NAME_LABEL,
  CURRENT_COMPANY,
  CURRENT_COMPANY_TITLE,
  CURRENT_SALARY,
  CURRENT_SALARY_ANNUAL,
  DATE_FORMAT1,
  DEPARTMENT,
  Designation,
  EDUCATION1,
  EDUCATION2,
  EMAIL_ADDRESS,
  EMAIL_ADDRESS_TITLE,
  END_DATE,
  END_DATE_TITLE,
  EXPECTED_SALARY,
  EXPECTED_SALARY_TITLE,
  LOCATION,
  LOCATION_TITLE,
  noticeOption,
  NOTICE_PERIOD,
  NOTICE_PERIOD_TITLE,
  PHONE_NUMBER,
  PHONE_NUMBER_TITLE,
  POSITION,
  POSITION_TITLE,
  progressOption,
  REASON_FOR_CHANGE,
  REASON_FOR_CHANGE_TITLE,
  SELECTION_PROGRESS,
  SELECTION_PROGRESS_TITLE,
  SOMETHING_WRONG,
  START_DATE,
  START_DATE_TITLE,
  SUBMIT,
  UPLOAD_DOCUMENT,
  WORK_EXPERIENCE_1,
  WORK_EXPERIENCE_2,
  FILE_NOT_UPLOAD,
  S3_BUCKET,
  BUCKET_FOLDER,
} from "../../utils/labelContant";
import { DateInput } from "semantic-ui-calendar-react";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { uploadPresigned } from "../../utils/presignedUpload";

const AddCandidate = (props) => {
  const [formValue, setFormValue] = useState({
    CandidateName: null,
    current_designation: null,
    location: null,
    current_company: null,
    current_salary: null,
    expected_salary: null,
    notice_period: null,
    reason_to_change: null,
    phone: null,
    email: null,
    docs: null,
    education1: null,
    education2: null,
    start_date: null,
    end_date: null,
    designation: null,
    department: null,
    annual_salary: null,
    annual_expected_salary: null,
    company_name: null,
    reason2: null,
    start_date2: null,
    end_date2: null,
    designation2: null,
    department2: null,
    change_reason: null,
    selection_progress: null,
    post: null,
  });
  const { history} = props;
  const [recruitment, setRecruitment] = useState();
  const [fileName, setFileName] = useState("");
  const handleUploadToS3 = async (files, cb) => {
    let file = files[0]?.name
    let f = files[0];
       uploadPresigned(f, BUCKET_FOLDER.CANDIDATE_RESUME)
         .then((res) => {
           const preDefineUrl = res[0]?.config?.url;
           const location = preDefineUrl?.substring(0, preDefineUrl?.indexOf("?"));
           const uplodedDocs = {
              Location: location,
              Bucket: S3_BUCKET,
              original_name:file
           }
          return cb(uplodedDocs)
          })
          .catch(() => {
            toast.error(FILE_NOT_UPLOAD, { toastId: SOMETHING_WRONG});
          });
  };

  const recruitmentData = () => {
    axios
      .get("dds/Recruitmentlist")
      .then((response) => {
        const data = response.data.result;
        const row = data.map((item, index) => {
          let newValues = {
            text: item.position,
            key: item.id,
            value: item.id,
          };
          return newValues;
        });
        setRecruitment([...row]);
      })
      .catch((e) => {
        setRecruitment([]);
      })
  };
  const getData = () => {
    let work_experiences = [
      {
        name: formValue.current_company,
        salary: formValue.current_salary,
        department: formValue.department,
        is_current: true,
        start_date: formValue.start_date,
        designation: formValue.designation,
        notice_period: formValue.notice_period,
        expected_salary: formValue.expected_salary,
        end_date: formValue.end_date,
        reason_for_change: formValue.reason2,
      },
      {
        name: formValue.company_name,
        start_date: formValue.start_date2,
        designation: formValue.designation2,
        department: formValue.department2,
        end_date: formValue.end_date2,
        reason_for_change: formValue.change_reason,
      },
    ];
    let qualifications = [
      { name: formValue.education1 },
      { name: formValue.education2 },
    ];
    let formData = new FormData();
    formValue?.docs != null && (formData.append("resume", formValue?.docs));
    formData.append("name", formValue.CandidateName);
    formData.append("email", formValue.email);
    formData.append("phone", formValue.phone);
    formData.append("current_company", formValue.current_company);
    formData.append("current_designation ", formValue.current_designation);
    formData.append("work_experiences", JSON.stringify(work_experiences));
    formData.append("qualifications", JSON.stringify(qualifications));
    formData.append("current_salary", formValue.current_salary);
    formData.append("location", formValue.location);
    formData.append("expected_salary", formValue.expected_salary);
    formData.append("notice_period", formValue.notice_period);
    axios
      .post(`hr/candidate/submission/${formValue.post}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.status === 201) {
          toast.success(CANDIDATE_TOAST, {
            toastId: "clientAddedSuccessfully",
          });

        }
      })
      .catch(async (e) => {
        let response = e?.response;
        toast.error(response?.data?.message || CATCH_TOAST, {
          toastId: "clientAddedError",
        });
      })
      .finally(() => {
        history.push(CANDIDATE);
      });
  };

  const onChange = (e) => {
    let newValues = { ...formValue, [e.target.name]: e.target.value };
    setFormValue({ ...newValues });
  };
  const onSubmit = async () => {
    setFormValue({ ...formValue });
    getData();
  };

  useEffect(() => {
    recruitmentData();
  }, []);
  return (
    <Grid className="sideMargin">
      <Helmet>
        <title> {"Add New Candidate"}</title>
        <meta name="description" content="Description of Jobs" />
      </Helmet>
      <div className={"container-height-width"}>
        <TitleWrapper>
          <div className="cusTitle">
            <ContainerTitle title={"Add New Candidate"} />
          </div>
        </TitleWrapper>
      </div>
      <Grid.Column mobile={16} tablet={16} computer={16}>
        <div style={{}}>
          <Segment raised padded className="chart-container-height">
            <div className="">
              <Form className="fontQuicksand">
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={CANDIDATE_ID}
                    placeholder={CANDIDATE_ID_TITLE}
                    width={6}
                    name="CandidateName"
                    value={formValue.CandidateName}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>
                  <Form.Field
                    control={Select}
                    label={POSITION}
                    placeholder={POSITION_TITLE}
                    searchInput={{
                      id: "form-select-control-manager",
                    }}
                    className="inlineLabel topMarginInput"
                    search
                    options={recruitment}
                    clearable
                    width={6}
                    value={formValue.post}
                    selectOnBlur={false}
                    name="post"
                    onChange={async (e, { value }) => {
                      e.target.value = await value;
                      e.target.name = await "post";
                      await onChange(e);
                    }}
                  />

                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={CANDIDATE_DESIGNATION}
                    placeholder={CANDIDATE_DESIGNATION_TITLE}
                    width={6}
                    name="current_designation"
                    value={formValue.current_designation}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={LOCATION}
                    placeholder={LOCATION_TITLE}
                    width={6}
                    name="location"
                    value={formValue.location}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>
                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={CURRENT_COMPANY}
                    placeholder={CURRENT_COMPANY_TITLE}
                    width={6}
                    name="current_company"
                    value={formValue.current_company}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={CURRENT_SALARY}
                    placeholder={CURRENT_SALARY_ANNUAL}
                    width={6}
                    name="current_salary"
                    value={formValue.current_salary}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={EXPECTED_SALARY}
                    placeholder={EXPECTED_SALARY_TITLE}
                    width={6}
                    name="expected_salary"
                    value={formValue.expected_salary}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>
                  <Form.Field
                    control={Select}
                    label={NOTICE_PERIOD}
                    placeholder={NOTICE_PERIOD_TITLE}
                    searchInput={{
                      id: "form-select-control-manager",
                    }}
                    className="inlineLabel topMarginInput"
                    search
                    options={noticeOption}
                    clearable
                    width={6}
                    value={formValue.notice_period}
                    selectOnBlur={false}
                    name="notice_period"
                    onChange={async (e, { value }) => {
                      e.target.value = await value;
                      e.target.name = await "notice_period";
                      await onChange(e);
                    }}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={REASON_FOR_CHANGE}
                    placeholder={REASON_FOR_CHANGE_TITLE}
                    width={6}
                    name="reason_to_change"
                    value={formValue.reason_to_change}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>

                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={PHONE_NUMBER}
                    placeholder={PHONE_NUMBER_TITLE}
                    width={6}
                    name="phone"
                    value={formValue.phone}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />

                  <Form.Field width={1}></Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={EMAIL_ADDRESS}
                    placeholder={EMAIL_ADDRESS_TITLE}
                    width={6}
                    name="email"
                    value={formValue.email}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                </Form.Group>

                <Form.Group
                  widths={16}
                  className="formGroup marginBottomNone mt-2"
                >
                  <Form.Field width={1}></Form.Field>

                  <Form.Field width={6}>
                    <span className="titleDeclare mb-2">{EDUCATION1}</span>
                  </Form.Field>

                  <Form.Field width={2}></Form.Field>

                  <Form.Field width={6}>
                    <span className="titleDeclare mb-2">{EDUCATION2}</span>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    placeholder={EDUCATION1}
                    width={6}
                    name="education1"
                    value={formValue.education1}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    placeholder={EDUCATION2}
                    width={6}
                    name="education2"
                    value={formValue.education2}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group
                  widths={16}
                  className="formGroup marginBottomNone mt-2"
                >
                  <Form.Field width={4}></Form.Field>

                  <Form.Field width={8}>
                    <div className="keyvaluebox header b-dotted-b">
                      <span className="titleDeclare mb-2">
                        {WORK_EXPERIENCE_1}
                      </span>
                    </div>
                  </Form.Field>
                  <Form.Field width={4}></Form.Field>
                </Form.Group>
                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={COMPANY_NAME_LABEL}
                    placeholder={COMPANY_NAME}
                    width={6}
                    name="company_name"
                    value={formValue.company_name}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>
                  <Form.Field
                    className="inlineLabel topMarginInput"
                    label={REASON_FOR_CHANGE}
                    placeholder={REASON_FOR_CHANGE_TITLE}
                    width={6}
                    name="reason2"
                    value={formValue.reason2}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>
                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <DateInput
                    label={START_DATE}
                    className={"inlineLabel topMarginInput"}
                    icon="calendar outline"
                    name="start_date"
                    closeOnMouseLeave
                    placeholder={START_DATE_TITLE}
                    value={formValue.start_date}
                    iconPosition="right"
                    dateFormat={DATE_FORMAT1}
                    hideMobileKeyboard
                    closable
                    width={6}
                    animation={"drop"}
                    clearable
                    onChange={(event, { value }) => {
                      event.target.value = value;
                      event.target.name = "start_date";
                      onChange(event);
                    }}
                  />
                  <Form.Field width={2}></Form.Field>

                  <DateInput
                    label={END_DATE}
                    className={"inlineLabel topMarginInput"}
                    icon="calendar outline"
                    name="end_date"
                    closeOnMouseLeave
                    placeholder={END_DATE_TITLE}
                    value={formValue.end_date}
                    iconPosition="right"
                    dateFormat={DATE_FORMAT1}
                    hideMobileKeyboard
                    closable
                    width={6}
                    animation={"drop"}
                    clearable
                    onChange={(event, { value }) => {
                      event.target.value = value;
                      event.target.name = "end_date";
                      onChange(event);
                    }}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    label={Designation}
                    placeholder={Designation}
                    width={6}
                    name="designation"
                    value={formValue.designation}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    label={DEPARTMENT}
                    placeholder={DEPARTMENT}
                    width={6}
                    name="department"
                    value={formValue.department}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group
                  widths={16}
                  className="formGroup marginBottomNone mt-2"
                >
                  <Form.Field width={4}></Form.Field>
                  <Form.Field width={8}>
                    <div className="keyvaluebox header b-dotted-b">
                      <span className="titleDeclare mb-2">
                        {WORK_EXPERIENCE_2}
                      </span>
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <DateInput
                    label={START_DATE}
                    className={"inlineLabel topMarginInput"}
                    icon="calendar outline"
                    name="start_date2"
                    closeOnMouseLeave
                    placeholder={START_DATE_TITLE}
                    value={formValue.start_date2}
                    iconPosition="right"
                    dateFormat={DATE_FORMAT1}
                    hideMobileKeyboard
                    closable
                    width={6}
                    animation={"drop"}
                    clearable
                    onChange={(event, { value }) => {
                      event.target.value = value;
                      event.target.name = "start_date2";
                      onChange(event);
                    }}
                  />
                  <Form.Field width={2}></Form.Field>

                  <DateInput
                    label={END_DATE}
                    className={"inlineLabel topMarginInput"}
                    icon="calendar outline"
                    name="end_date2"
                    closeOnMouseLeave
                    placeholder={END_DATE_TITLE}
                    value={formValue.end_date2}
                    iconPosition="right"
                    dateFormat={DATE_FORMAT1}
                    hideMobileKeyboard
                    closable
                    width={6}
                    animation={"drop"}
                    clearable
                    onChange={(event, { value }) => {
                      event.target.value = value;
                      event.target.name = "end_date2";
                      onChange(event);
                    }}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    label={Designation}
                    placeholder={Designation}
                    width={6}
                    name="designation2"
                    value={formValue.designation2}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={2}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    label={DEPARTMENT}
                    placeholder={DEPARTMENT}
                    width={6}
                    name="department2"
                    value={formValue.department2}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field width={1}></Form.Field>

                  <Form.Field
                    className="inlineLabel mt-1"
                    label={REASON_FOR_CHANGE}
                    placeholder={REASON_FOR_CHANGE_TITLE}
                    width={6}
                    name="change_reason"
                    value={formValue.change_reason}
                    onChange={(e) => onChange(e)}
                    control={Input}
                  />
                  <Form.Field width={1}></Form.Field>
                </Form.Group>

                <Form.Group
                  widths={16}
                  className="formGroup marginBottomNone mt-2"
                >
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={3} className="titleDeclare mb-2">
                    {ATTACH_DOCUMENT}
                  </Form.Field>
                </Form.Group>

                <Form.Group className="w-100">
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={8}>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleUploadToS3(acceptedFiles, (data) => {
                          setFormValue((prevState) => ({
                            ...prevState,
                            docs: data?.Location || "",
                          }));
                          setFileName( data?.original_name);

                        })
                      }
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="w-100">
                          <input {...getInputProps()} />
                          <Item className="dotted w-100">
                            <Header className="w-100" icon>
                              <Icon
                                className="iconOrange"
                                name="cloud upload"
                              />

                              <p>{UPLOAD_DOCUMENT}</p>
                            </Header>
                          </Item>
                        </div>
                      )}
                    </Dropzone>
                  </Form.Field>
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field width={1}></Form.Field>
                  <div className="TemplateEditor__section-upload-container">
                    <div className="w-100 uploads">
                      {fileName?.length ? (<Form.Field width={16}>
                        <div className="item">
                          <span>
                            {fileName}
                          </span>
                          <button
                            type="button"
                            onClick={() => {
                              setFileName("")
                            }}
                          >
                            <Icon name="delete" />
                          </button>
                        </div>
                      </Form.Field>) : ""}
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="formGroup " widths={16}>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field
                    control={Select}
                    label={SELECTION_PROGRESS}
                    placeholder={SELECTION_PROGRESS_TITLE}
                    searchInput={{
                      id: "form-select-control-manager",
                    }}
                    className="inlineLabel topMarginInput"
                    search
                    options={progressOption}
                    clearable
                    width={6}
                    value={formValue.selection_progress}
                    selectOnBlur={false}
                    name="selection_progress"
                    onChange={async (e, { value }) => {
                      e.target.value = await value;
                      e.target.name = await "selection_progress";
                      await onChange(e);
                    }}
                  />
                </Form.Group>

                <div className="buttonDiv mt-2">
                  <Button
                    type="button"
                    className="dotButton"
                    onClick={() => onSubmit()} // validate and push code to next page through properties and use Hostory
                  >
                    {SUBMIT}
                  </Button>
                </div>
              </Form>
            </div>
          </Segment>
        </div>
      </Grid.Column>
    </Grid>
  );
};
export default AddCandidate;
