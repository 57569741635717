import { createSelector } from 'reselect';
import { INITIAL_STATE } from './reducers';

/**
 * Direct selector to the home state domain
 */

const selectEventDomain = state => state.event || INITIAL_STATE;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Home
 */

const makeSelectEvent = () =>
  createSelector(
    selectEventDomain,
    event => event,
  );

export default makeSelectEvent;
export { selectEventDomain , makeSelectEvent };
