/**
 *
 * ContainerTitle
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function ContainerTitle({ title }) {
  return <div className="title"> {title}</div>;
}

ContainerTitle.propTypes = {};

export default ContainerTitle;
