/**
 *
 * Modals
 *
 */

import React, { memo, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import TestModal from "../TestModal/TestModal";
import { makeSelectModal } from "../../redux/redux/modals/selectors";
import AddPlaning from "../../containers/Masters/MasterModals/AddPlaning";
import AddCurrency from "../../containers/Masters/MasterModals/AddCurrency";
import AddCountry from "../../containers/Masters/MasterModals/AddCountry";
import AddAttendancetype from "../../containers/Masters/MasterModals/AddAttendancetype";

const RouteErrorModal = React.lazy(() =>
  import("../../components/CreateJob/RouteErrorModal")
);
const AddCompany = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddCompany")
);
const AddPriority = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddPriority")
);
const AddVat = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddVat")
);
const AddDepartment = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddDepartment")
);
const AddGrade = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddGrade")
);
const AddJobtype = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddJobtype")
);
const AddTat = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddTat")
);
const AddRole = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddRole")
);
const AddTraining = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddTraining")
);
const AddStatus = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddStatus")
);
const AddTeam = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddTeam")
);
const ViewLeaves = React.lazy(() =>
  import("../../containers/Staff/Add Staff/Leaves/ViewLeaves")
);
const ApplyLeave = React.lazy(() =>
  import("../../containers/MyAccount/Leaves/ApplyLeave")
);
const RoleUnAuthorize = React.lazy(() => import("./RoleUnAuthorize"));
const MySwipes = React.lazy(() => import("../../containers/Home/MySwipes"));
const AddPayment = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddPayment")
);
const AddLeaveType = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddLeavetype")
);
const AddHolidayType = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddHolidaytype")
);
const AddShiftType = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddShiftType")
);
const AddCandidateStatus = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddCandidateStatus")
);
const AddSkillsCategory = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddSkillsCategory")
);
const AddLeaves = React.lazy(() =>
  import("../../containers/Masters/MasterModals/AddLeaves")
);
const DeleteAction = React.lazy(() => import("./DeleteAction"));
const UpdateAction = React.lazy(() => import("./UpdateAction"));
function Modals({ activeModal, ...props }) {
  const modalLookup = {
    TestModal,
    RouteErrorModal,
    AddCompany,
    AddPriority,
    AddVat,
    AddDepartment,
    AddGrade,
    AddJobtype,
    AddTat,
    AddRole,
    AddTraining,
    AddStatus,
    AddTeam,
    ViewLeaves,
    ApplyLeave,
    RoleUnAuthorize,
    MySwipes,
    AddPayment,
    AddLeaveType,
    AddHolidayType,
    AddShiftType,
    AddCandidateStatus,
    AddSkillsCategory,
    AddLeaves,
    DeleteAction,
    UpdateAction,
    AddPlaning,
    AddCurrency,
    AddCountry,
    AddAttendancetype,
  };
  let renderModal;
  if (activeModal) {
    const { modalType, modalProps } = activeModal;
    const ModalComponent = modalLookup[modalType];
    renderModal = (
      <Suspense fallback={null}>
        <ModalComponent {...modalProps}  />
      </Suspense>
    );
  }

  return <>{renderModal}</>;
}

Modals.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activeModal: makeSelectModal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Modals);
