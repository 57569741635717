import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2, gsap } from "gsap";
import OtpInput from "react-otp-input";
import axios from "../../utils/axios";
import PuffLoader from "react-spinners/PuffLoader";
import {
  setUserDetails,
  setAccessToken,
  setUserName,
  setAccess,
} from "../../redux/redux/global/action";
import {AccessCheck} from "../../utils/AccessCheck"

gsap.registerPlugin([TimelineLite]);
export default function CodeVerification(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [classHeader, setClassHeader] = useState("");
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState("");
  const [randomMessage, setRandomMessage] = useState([
    {
      label: "1st",
      value: 1,
    },
    {
      label: "2nd",
      value: 2,
    },
    {
      label: "3rd",
      value: 3,
    },
    {
      label: "4th",
      value: 4,
    },
    {
      label: "5th",
      value: 5,
    },
    {
      label: "6th",
      value: 6,
    },
    {
      label: "7th",
      value: 7,
    },
    {
      label: "8th",
      value: 8,
    },
  ]);

  const getCodeBoxElement = (index) => {
    return document.getElementById("codeBox" + index);
  };
  const onKeyUpEvent = (index, event) => {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 6) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  };

  const loginTypeValue = () => {
    if (props.loginType === "DOT_TEAM") {
      return { role: 4 };
    }
    return {};
  };
  const login = (formData) => {
    let value = { ...formData, ...loginTypeValue() };

    setBtnSubmitted(true);
    axios
      .post("/login/login-with-key", value, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUserDetails(response.data.result));
          dispatch(setAccessToken(response.data.result.token));
          if (response.data.result && response.data.result.uname) {
            dispatch(setUserName(response.data.result.uname));
          }
          if (response.data.result) {
            history.push({
              pathname: "/dashboard",
              data: response.data.result,
            });
          } else {
            setOtpError("Invalid Keyword");
          }
        }
      })
      .catch((e) => {
        setOtpError(e?.response?.data?.result?.message || "Invalid Keyword");
      })
      .finally(() => {
        setBtnSubmitted(false);
      });
  };
  const validate = () => {
    if (otp == "" || otp.length < 3) {
      setOtpError("Please enter Keyword");
    } else {
      let temp = {
        email: props.formvalue.email,
        keyword: otp,
        position: props.random,
      };
      return login(temp);
    }
  };
  const onOtpChange = (e) => {
    setOtp(e);
    setOtpError("");
  };
  const positionPicker = () => {
    return (
      randomMessage[props.random[0] - 1].label +
      ", " +
      randomMessage[props.random[1] - 1].label +
      " and " +
      randomMessage[props.random[2] - 1].label
    );
  };
  useEffect(() => {
    if (props.title === "Team Login") {
      setClassHeader("-blue");
    } else {
      setClassHeader("");
    }
    let recovery = new TimelineLite({
      paused: true,
      smoothChildTiming: true,
      reversed: true,
      yoyo: true,
    });
    recovery
      .fromTo(
        "#code_verification",
        { opacity: 0, ease: Power2.easeInOut, y: -50 },
        { opacity: 1, ease: Power2.easeInOut, y: 0 }
      )
      .play();
    return () => { };
  }, []);
  return (
    <div className="client-login recovery" id="code_verification">
      <p className={`login-input-header ` + classHeader}>VERIFY KEYWORD</p>
      <p className={`forgot-password-description-verify` + classHeader}>Enter {positionPicker()} Letter of your security Keyword</p>
      <div className="input-form-control centerFlex" name='formOtp' style={{ width: "auto" }}>
        <OtpInput
          value={otp}
          onChange={onOtpChange}
          numInputs={3}
          shouldAutoFocus
          inputStyle={"inputStyleCus"}
          id={"otp"}
        />
        <div className="errorLabel">{otpError}</div>
        <div className="successLabel">{emailSuccess}</div>
      </div>
      <div className={`input-form-control` + classHeader}>
        <label
          className="input-form-control-label-forgot-password"
          onClick={() => props.onForgoPassword("SECURITY")}
        >
          Forgot Keyword ?
        </label>
      </div>
      <button
        tabIndex={0}
        className="login-type-submit loginBottom8 focusButton"
        style={{
          height: window.innerHeight / 12,
          width: window.innerHeight / 12,
          fontSize: "85%",
        }}
        onClick={() => validate()}
        disabled={btnSubmitted}
      >
        {btnSubmitted ? (
          <PuffLoader color={"#FFFFFF"} loading={true} size={40} />
        ) : (
          "Log In"
        )}
      </button>
    </div>
  );
}
