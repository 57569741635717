import React from "react";
import cx from "classnames";
import { Editor, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import rcEt from "../../styles/components/_editor.scss";
import "../../styles/components/_editor.scss";
import { stateToHTML } from "draft-js-export-html";
// import Button from "./Button";
import BlockStyleControls from "./EditorBlockStyleControls";
import InlineStyleControls from "./EditorInlineStyleControls";
import { Button } from "semantic-ui-react";

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

export default class RichEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty(), commentData: "", editorContentHtml: "" };
  }

  onChange = (editorState) => {
    this.setState({ editorState, commentData: stateToHTML(editorState.getCurrentContent()) });
  };
  onDelete = () => {
    // const editorState = EditorState.push(this.state.editorState, commentData.createFromText(''));
    // this.setState({commentData:EditorState.createEmpty()});
    // this.setState({
    //   commentData: "",
    // })
    // this.props.onDelete();
  };
  shouldComponentUpdate(nextProps, nextState) {
    const myProps = this.props !== nextProps;
    const myState = this.state !== nextState;
    return myProps || myState;
  }
  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  onTab = (e) => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };

  getBlockStyle = (block) => {
    switch (block.getType()) {
      case "blockquote":
        return rcEt["RichEditor-blockquote"];
      default:
        return null;
    }
  };
  sendComment = () => {
    // this.props.refreshData(stateToHTML(this.state.editorState.getCurrentContent()));
    // this.props.onSend(stateToHTML(this.state.editorState.getCurrentContent()));
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };
  
  render() {
    const { editorState } = this.state;
    let className = "RichEditor-editor "; // Do not remove the space
    let contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += ` RichEditor-hidePlaceholder`; // Do not remove the space
      }
    }

    return (
      <>
      <div className={cx("RichEditor-root")}>
        <div className={className}>
          <Editor
            blockStyleFn={this.getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder="Write Here"
            spellCheck={true}
          />
        </div>
        <div className={"controller"}>
          <div className={"controllerFlex"}>
            <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
            <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
          </div>
          <div className={"controllerButtonFlex"}>
            {/* <button id="delete" styleClass={"center"} buttonStyle={"secondary"} onClick={this.onDelete}>
              <DeleteIcon fill="#11A1FD" color="#11A1FD" />
              delete
            </button> */}
            {/* <Button color="red" className="font-quicksand" onClick={this.onDelete}>
              Delete
            </Button> */}
            {/* onClick={this.onDelete} */}
            
            {/* onClick={this.sendComment} */}
            {/* <Button id="reply" styleClass={"center"} onClick={this.sendComment}>
              <span><SendIcon fill="white" /></span>
              Send
            </Button> */}
          </div>
        </div>
      </div>
      <div className="buttonDiv topMarginInput">
                  <Button
                    type="submit"
                    className="dotButtonEnquiry"
                    onClick={this.sendComment}
                    // loading={loadingOnSubmit}
                    // disabled={loadingOnSubmit}
                  >
                    Submit &amp; Next
                    {/* {props.match && props.match.type && props.match.id
                      ? "Update"
                      : "Submit"} */}
                  </Button>
                </div>
      </>
    );
  }
}
