import React from "react";
import { Card, Image, Placeholder } from "semantic-ui-react";
import cx from "classnames";

function TableTab({
  title,
  onClickfn,
  image,
  value,
  activeImage,
  activeId,
  tabId,
  cKey,
  loading,
  ...props
}) {
  const renderPlaceholders = () => {
    if (loading) {
      return (
        <Card
          className={cx("table-tab", { active: tabId === activeId })}
          onClick={() => onClickfn(tabId)}
          key={cKey}
          id={tabId}
        >
          <Card.Content
            className={"card-table-content"}
            style={{ overflow: "hidden" }}
          >
            {/* <Image
            floated="left"
            size="tiny"
            src={tabId === activeId ? activeImage : image}
            verticalAlign={"top"}
          /> */}
            <Placeholder style={{ width: 40, height: 40, borderRadius: "50%" }}>
              <Placeholder.Image />
            </Placeholder>
            <Card.Description className="card-table-description">
              <Placeholder style={{width:"100%", backGroundColor:'transparent'}}>
                {/* <Placeholder.Paragraph> */}
                  <Placeholder.Line />
                {/* </Placeholder.Paragraph> */}
              </Placeholder>
              <Placeholder style={{width:"100%", backGroundColor:'transparent'}}>
                {/* <Placeholder.Paragraph> */}
                  <Placeholder.Line />
                {/* </Placeholder.Paragraph> */}
              </Placeholder>
            </Card.Description>
          </Card.Content>
        </Card>
      );
    }
    return (
      <Card
        className={cx("table-tab", { active: tabId === activeId })}
        onClick={() => onClickfn(tabId)}
        key={cKey}
        id={tabId}
      >
        <Card.Content
          className={"card-table-content"}
          style={{ overflow: "hidden" }}
        >
          <Image
            floated="left"
            size="tiny"
            src={tabId === activeId ? activeImage : image}
            verticalAlign={"top"}
          />
          <Card.Description className="card-table-description">
            {/* <div className="card-count">{value}</div> */}
            <div className={"card-title"}>{title}</div>
            <div className="card-count">Total: {value}</div>
            {/* Count */}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  };
  return <> 
  <Card
        className={cx("table-tab", { active: tabId === activeId })}
        onClick={() => onClickfn(tabId)}
        key={cKey}
        id={tabId}
      >
        <Card.Content
          className={"card-table-content"}
          style={{ overflow: "hidden" }}
        >
          <Image
            floated="left"
            size="tiny"
            src={tabId === activeId ? activeImage : image}
            verticalAlign={"top"}
          />
          <Card.Description className="card-table-description">
            {/* <div className="card-count">{value}</div> */}
            <div className={"card-title"}>{title}</div>
            <div className="card-count">Total: {value}</div>
            {/* Count */}
          </Card.Description>
        </Card.Content>
      </Card>
   </>;
}
export default TableTab;
