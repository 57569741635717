import React, { useCallback,  useState ,Fragment} from "react";
import styled, { keyframes } from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { get_random } from "../../utils/randomNumber";
const Bubble = styled.div`
  animation: ${(props) => props.bubble} 3s linear;
  ${(props) => props.directionRL}: ${(props) => props.x}%;
  ${(props) => props.directionUD}: ${(props) => props.y}%;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 2s;
`;
const bubbleAnimationData = (x = -3, y = -9, height = 250, width = 250) => {
  return {
    x: x,
    y: y,
    directionRL: get_random(["left", "right"]),
    directionUD: get_random(["top", "bottom"]),
    height: height,
    width: width,
  };
};
function Bubbles() {
  const [bubbleLocationData] = useState(
    [
      bubbleAnimationData(-3, -9, 250, 250),
      bubbleAnimationData(2, 14, 280, 280),
      bubbleAnimationData(2, 80, 260, 260),
      bubbleAnimationData(20, 5, 300, 300),
      bubbleAnimationData(46, 20, 300, 300),
      bubbleAnimationData(45, 55, 280, 280),
      bubbleAnimationData(80, 56, 300, 300),
      bubbleAnimationData(40, 90, 400, 400),
      bubbleAnimationData(70, 84, 300, 300),
      bubbleAnimationData(15, 50, 250, 250),
      bubbleAnimationData(60, 40, 220, 220),
      bubbleAnimationData(99, 80, 300, 300),
      bubbleAnimationData(43, 56, 300, 300),
      bubbleAnimationData(80, 56, 300, 300),
      bubbleAnimationData(25, 56, 300, 300),
    ]
  )
  const bubbleAnimation = useCallback(
    (x, y, directionRL, directionUD, height = 250, width = 250) => {
      const bubble = keyframes`
              0%   {${directionRL}: ${x}%; ${directionUD}: ${y}%; transform: scale(1);}
              20%   {${directionRL}: ${x - 5}%; ${directionUD}: ${
        y - 5
      }%; transform: scale(1);}
              40%   {${directionRL}: ${
        x - 10
      }%; ${directionUD}: ${y}%; transform: scale(1.2);}
              60%   {${directionRL}: ${x}%; ${directionUD}: ${y}%; transform: scale(1.1);}
              80%   {${directionRL}: ${x + 5}%; ${directionUD}: ${
        y + 10
      }%; transform: scale(1.2);}
              100%   {${directionRL}: ${x}%; ${directionUD}: ${y}%; transform: scale(.95);}
      `;

      return ( <Bubble
            className="bubbles"
            bubble={bubble}
            x={x}
            y={y}
            directionRL={directionRL}
            directionUD={directionUD}
            height={height}
            width={width}
            key={uuidv4()}
          />
      );
    },
    []
  );

  const renderBubble = useCallback(() => {
    return bubbleLocationData.map((item, index) => {
      return bubbleAnimation(
        item.x,
        item.y,
        item.directionRL,
        item.directionUD,
        item.height,
        item.width
      );
    });
  }, [bubbleAnimation, bubbleLocationData]);
  return renderBubble();
}

export default Bubbles;
