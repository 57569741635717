import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import addClientReducer from "./redux/addClient/reducers";
import addEnquiryReducer from "./redux/addEnquiry/reducers";
import clientsReducer from "./redux/clients/reducers";
import notificationReducer from "./redux/notification/reducers";
import deviceReducer from "./redux/device/reducers";
import globalReducer from "./redux/global/reducers";
import homeReducer from "./redux/home/reducers";
import jobdetailsReducer from "./redux/jobdetails/reducers";
import jobReducer from "./redux/job/reducers";
import enquiryReducer from "./redux/enquiry/reducers";
import eventReducer from "./redux/event/reducers";
import my_account_leave_reducer from "./redux/leaves/reducers";
import loginReducer from "./redux/login/reducers";
import masterReducer from "./redux/masters/reducers";
import modalsReducer from "./redux/modals/reducer";
import myAccountReducer from "./redux/myaccount/reducers";
import staffReducer from "./redux/staff/reducers";
import staffProfileReducer from "./redux/staffProfile/reducers";
import invoiceReducer from "./redux/invoices/reducers";
import accessReducer from "./redux/access/reducers";
import recurrenceReducer from "./redux/recurrenceJobs/reducers";
import archiveReducer from "./redux/archiveJob/reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["global"],
};

const reducers = combineReducers({
  device: deviceReducer,
  global: globalReducer,
  home: homeReducer,
  login: loginReducer,
  clients: clientsReducer,
  notification: notificationReducer,
  addClient: addClientReducer,
  addEnquiry: addEnquiryReducer,
  modals: modalsReducer,
  staff: staffReducer,
  staffProfile: staffProfileReducer,
  master: masterReducer,
  job: jobReducer,
  event: eventReducer,
  enquiry: enquiryReducer,
  jobdetails: jobdetailsReducer,
  myAccount: myAccountReducer,
  myAcLeave: my_account_leave_reducer,
  invoice: invoiceReducer,
  access: accessReducer,
  recurrence: recurrenceReducer,
  archive: archiveReducer
});

export default persistReducer(persistConfig, reducers);