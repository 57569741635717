import { useEffect, useState } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { EXITREASONOPTIONS } from "../../../utils/Constant"
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Segment,
  Select,
} from "semantic-ui-react";
import { stringType, isDate, isSelectEmpty } from "../../../utils/validator";
import axios from "../../../utils/axios"
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import moment from "moment";
import { store } from "../../../redux/store";
const ExitForm = (props) => {
  const { match, history } = props;
  const [staffData, setStaffData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orignalTick, setOrignalTick] = useState(false);
  const [staffId, setStaffId] = useState("");
  const [exitFormValue, setExitFormValue] = useState({
    employee_set: "",
    original_hod_id: "",
    employee_name: null,
    department: "",
    designation: "",
    date_joining: "",
    date_reg: "",
    lwd: "",
    reason: "",
    emp_code: null,
    user_id: null,
    role_id: null,
    dept_hod_id: "",
    sendexit: true,
  });
  const [exitFormValueError, setExitFormValueError] = useState({
    employee_setError: "",
    manager_idError: "",
    departmentError: "",
    designationError: "",
    date_joiningError: "",
    date_regError: "",
    lwdError: "",
    reasonError: "",
  });
  const onValidator = (e) => {
    switch (e.target.name) {
      case "employee_set":
        return stringType(e.target.value, "Employee Name");
      case "department":
        return stringType(e.target.value, "Department");
      case "designation":
        return stringType(e.target.value, "Designation");
      case "date_joining":
        return isDate(e.target.value, "Date of joining");
      case "date_reg":
        return isDate(e.target.value, "Date Of Registration");
      case "lwd":
        return isDate(e.target.value, "Last working date");
      case "reason":
        return isSelectEmpty(e.target.value, "Last working date");
      case "dept_hod_id":
        return isSelectEmpty(e.target.value, "Head of department");
      default:
        return null;
    }
  };

  const onChange = (e) => {
    let newValues = { ...exitFormValue, [e.target.name]: e.target.value };
    let errorData = {
      ...exitFormValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    setExitFormValue({ ...newValues });
    setExitFormValueError({ ...errorData });
  };
  // fetch all staff data
  const fetchStaff = () => {
    let result = [];
    let resultManager = [];
    axios
      .get("/staff/fetchAllDotStaff")
      .then((res) => {
        result = [...res.data.result.rows];
        let filterDoc1 = result.filter((e) => e.role !== 3);
        let filterDocManager = result.filter((e) => e.role !== 1);
        result = filterDoc1.map((item, index) => {
         let emp_id=  item.employee_id.includes('-') ? item.employee_id.replace(/-/g, '_') : item.employee_id;
         let designation= item.designation.includes('-') ?item.designation.replace(/-/g, ' ') :item.designation;
          return {
            text: item.employee_name,
            value: (item.id + "-" + item.role + "-" + item.employee_name + "-" + emp_id + "-" + designation + "-" + item.department + "-" + item.doj.split('-').join('/') + "-" + item.reporting_manager),
          };
        });
        resultManager = filterDocManager.map((item, index) => {
          return {
            text: item.employee_name,
            value: item.id,
          };
        });
        setStaffData([...result]);
        setManagerData([...resultManager]);
      })
      .catch((err) => { })
      .finally(() => {
      });
  }
  // get data using id
  const getData = (value) => {
    let result = [];
    axios
      .get(`/staff/fetchAllExit?id=${value}`)
      .then((res) => {
        result = res?.data?.result[0]?.response_fetchExits[0];
        setExitFormValue({
          employee_set: ((result?.user_id) + "-" + result?.role_id + "-" + result?.employee_name + "-" + result?.emp_code + "-" + result?.designation + "-" + result?.department + "-" + moment(result?.date_joining, 'YYYY-MM-DD').format('DD-MM-YYYY').split('-').join('/') + "-" + result?.original_hod_id),
          employee_name: result?.employee_name,
          original_hod_id: result?.original_hod_id,
          department: result?.department,
          designation: result?.designation,
          date_joining: moment(result?.date_joining, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          date_reg: moment(result?.date_reg, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          lwd: moment(result?.lwd, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          reason: result?.reason,
          emp_code: result?.emp_code,
          user_id: result?.user_id,
          role_id: result?.role_id,
          dept_hod_id: result?.dept_hod_id,
          sendexit: result?.sendexit == 1 ? true : false,
        });
        setOrignalTick(result?.sendexit == 1 ? true : false)
      })
      .catch((err) => { })
      .finally(() => {
      });
  }
  const validate = () => {
    let isError = false;
    Object.values({ ...exitFormValue }).forEach((value) => {
      if (value === "") {
        isError = true;
      }
    });
    return isError;
  };
  const onSubmitValidate = async () => {
    let data = { ...exitFormValue };
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };

      await setExitFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };
  // post API call for submit exit form
  const submitValue = async () => {
    let userData = store.getState().global;
    let isError = await validate();
    if (!isError) {
      let formValue = exitFormValue
      formValue.date_joining = moment(formValue.date_joining, 'DD-MM-YYYY').format('YYYY-MM-DD')
      formValue.date_reg = moment(formValue.date_reg, 'DD-MM-YYYY').format('YYYY-MM-DD')
      formValue.lwd = moment(formValue.lwd, 'DD-MM-YYYY').format('YYYY-MM-DD')
      formValue.hr_id = userData.userDetails.id
      delete formValue.employee_set
      setLoading(true);
      axios
        .post("/staff/createExit", formValue)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Exit Created Successfully", {
              toastId: "clientAddedSuccessfully",
            });
            props.history.replace("/exitList");
          }
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response?.data?.message ||
            "Something went wrong, Please try again later",
            {
              toastId: "clientAddedError",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please enter all mandatory fields", {
        toastId: "JobInvalidData",
      });
      onSubmitValidate();
    }
  }
  // update value of exist form 
  const updateValue = async () => {
    let isError = await validate();
    if (!isError) {
      let formValue = exitFormValue
      formValue.date_joining = moment(formValue.date_joining, 'DD-MM-YYYY').format('YYYY-MM-DD')
      formValue.date_reg = moment(formValue.date_reg, 'DD-MM-YYYY').format('YYYY-MM-DD')
      formValue.lwd = moment(formValue.lwd, 'DD-MM-YYYY').format('YYYY-MM-DD')
      delete formValue.employee_set
      setLoading(true);
      axios
        .put("/staff/updateExit", formValue)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Exit Created Successfully", {
              toastId: "clientAddedSuccessfully",
            });
            props.history.replace("/exitList");
          }
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response?.data?.message ||
            "Something went wrong, Please try again later",
            {
              toastId: "clientAddedError",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please enter all mandatory fields", {
        toastId: "JobInvalidData",
      });
      onSubmitValidate();
    }
  }
  useEffect(() => {
    if (match?.params?.type === "update" && match.params.id) {
      setStaffId(match.params.id);
      getData(match.params.id);
    }
    fetchStaff();
  }, []);
  return (
    <div className={"container-height-width"}>
      <div className="container-height-width">
        <Grid centered>
          <Segment raised className="container-height-width overflowInherit">
            <div className="attendanceTopInputsContainer">
              <Form>
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field
                    control={Select}
                    label="NAME"
                    clearable={true}
                    disabled={match?.params?.type === "update"}
                    className="inlineLabel"
                    placeholder="NAME"
                    width={8}
                    search={true}
                    options={staffData}
                    name="employee_set"
                    value={exitFormValue.employee_set}
                    onChange={(e, { value }) => {
                      let splitData = value.split('-')
                      let tempValue = exitFormValue
                      tempValue.user_id = splitData[0] || "";
                      tempValue.employee_name = splitData[2] || "";
                      tempValue.emp_code = splitData[3] || "";
                      tempValue.role_id = splitData[1] || "";
                      tempValue.designation = splitData[4] || "";
                      tempValue.department = splitData[5] || "";
                      tempValue.date_joining = splitData[6].split('/').join('-') || "";
                      tempValue.dept_hod_id = +splitData[7] || "";
                      tempValue.original_hod_id = +splitData[7] || "";
                      setExitFormValue(tempValue)
                      e.target.name = "employee_set";
                      e.target.value = value;
                      onChange(e);
                    }}
                    error={
                      exitFormValueError.employee_setError
                        ? exitFormValueError.employee_setError
                        : null
                    }
                  />
                  <Form.Field
                    control={Input}
                    label="DESIGNATION"
                    clearable="true"
                    value={exitFormValue.designation}
                    className="inlineLabel"
                    placeholder="DESIGNATION"
                    width={8}
                    disabled={orignalTick && match?.params?.type === "update"}
                    name="designation"
                    onChange={(e) => onChange(e)}
                    error={
                      exitFormValueError.designationError
                        ? exitFormValueError.designationError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field
                    control={Input}
                    label="DEPARTMENT"
                    clearable="true"
                    className="inlineLabel topMarginInput"
                    placeholder="DEPARTMENT"
                    selectonblur="false"
                    search="true"
                    value={exitFormValue.department}
                    width={8}
                    disabled={orignalTick && match?.params?.type === "update"}
                    name="department"
                    onChange={(e) => onChange(e)}
                    error={
                      exitFormValueError.departmentError
                        ? exitFormValueError.departmentError
                        : null
                    }
                  />
                  <Form.Field
                    control={Select}
                    label="HEAD OF DEPARTMENT"
                    clearable={true}
                    className="inlineLabel topMarginInput"
                    placeholder="HEAD OF DEPARTMENT"
                    width={8}
                    disabled={orignalTick && match?.params?.type === "update"}
                    search={true}
                    options={managerData}
                    name="dept_hod_id"
                    value={exitFormValue.dept_hod_id}
                    onChange={(e, { value }) => {
                      e.target.name = "dept_hod_id";
                      e.target.value = value;
                      onChange(e);
                    }}
                    error={
                      exitFormValueError.dept_hod_idError
                        ? exitFormValueError.dept_hod_idError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <DateInput
                    popupPosition="bottom center"
                    label="DATE OF JOINING"
                    className={"inlineLabel topMarginInput"}
                    // icon={"calendar outline large"}
                    name="date_joining"
                    closeOnMouseLeave
                    placeholder="DATE OF JOINING"
                    clearable={true}
                    disabled={orignalTick && match?.params?.type === "update"}
                    search="true"
                    value={exitFormValue.date_joining}
                    iconPosition="right"
                    width={8}
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}

                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "date_joining";
                      onChange(event);
                    }}
                    error={
                      exitFormValueError.date_joiningError
                        ? exitFormValueError.date_joiningError
                        : null
                    }
                  // maxDate={moment()}
                  />
                  <DateInput
                    popupPosition="bottom center"
                    label="DATE OF RESIGNATION"
                    className={"inlineLabel topMarginInput"}
                    // icon={"calendar outline large"}
                    name="date_reg"
                    closeOnMouseLeave
                    disabled={orignalTick && match?.params?.type === "update"}
                    placeholder="DATE OF RESIGNATION"
                    clearable={true}
                    search="true"
                    value={exitFormValue.date_reg}
                    iconPosition="right"
                    width={8}
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "date_reg";
                      onChange(event);
                    }}
                    error={
                      exitFormValueError.date_regError
                        ? exitFormValueError.date_regError
                        : null
                    }
                  // maxDate={moment()}
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <DateInput
                    popupPosition="bottom center"
                    label="LAST WORKING DATE"
                    className={"inlineLabel topMarginInput"}
                    // icon={"calendar outline large"}
                    name="lwd"
                    closeOnMouseLeave
                    placeholder="LAST WORKING DATE"
                    clearable={true}
                    search="true"
                    disabled={orignalTick && match?.params?.type === "update"}
                    value={exitFormValue.lwd}
                    iconPosition="right"
                    width={8}
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "lwd";
                      onChange(event);
                    }}
                    error={
                      exitFormValueError.lwdError
                        ? exitFormValueError.lwdError
                        : null
                    }
                  // maxDate={moment()}
                  />
                  <Form.Field
                    control={Select}
                    label="REASON"
                    clearable={true}
                    options={EXITREASONOPTIONS}
                    className="topMarginInput inlineLabel"
                    placeholder="REASON"
                    width={8}
                    disabled={orignalTick && match?.params?.type === "update"}
                    name="reason"
                    value={exitFormValue.reason}
                    onChange={(e, { value }) => {
                      e.target.name = "reason";
                      e.target.value = value;
                      onChange(e);
                    }}
                    error={
                      exitFormValueError.reasonError
                        ? exitFormValueError.reasonError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field
                    className="topMarginInput"
                    label="Send Exit Form"
                    name="sendexit"
                    control={Checkbox}
                    disabled={orignalTick && match?.params?.type === "update"}
                    checked={exitFormValue.sendexit}
                    // value={exitFormValue.sendexit}
                    onChange={() => {
                      let value = exitFormValue;
                      value.sendexit = !value.sendexit;
                      setExitFormValue({ ...value });
                    }}
                  />
                </Form.Group>
                {orignalTick && match?.params?.type === "update" ? "" : (
                  <Button
                    floated="left"
                    className="dotButton"
                    content="Submit"
                    color="green"
                    onClick={() => {
                      if (match?.params?.type === "update" && match.params.id) {
                        updateValue()
                      } else {
                        submitValue()
                      }
                    }}
                  />
                )}
              </Form>
            </div>
          </Segment>
        </Grid>
      </div>
    </div>
  );
};
export default compose(withRouter)(ExitForm);