import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";

export const initialState = {};
const sagaMonitor =
  process.env.NODE_ENV === "development"
    ? console.tron.createSagaMonitor()
    : null;
const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
});


const enhancer =
  process.env.NODE_ENV === "development"
    ? compose(

      applyMiddleware(
        sagaMiddleware

      )

    )
    : applyMiddleware(sagaMiddleware);

export const store = createStore(rootReducer, initialState, enhancer);
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
const ex = { store, persistStore };
export default ex;
