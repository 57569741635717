import moment from "moment";
import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Button, Form, Grid, Item, Progress, Segment } from "semantic-ui-react";
import RichEditor from "../../../../components/MarketingEditor/Editor"

function Feedback(props) {
  return (
    <Grid.Column
      mobile={16}
      tablet={16}
      computer={16}
      className="topBottomMarginNone topMarginInput"
    >
      <div>
        <Segment raised className="chart-container-height">
          <div className="formContainer">
            <div className="progressHeading">Client Feedback</div>
            <RichEditor
            // onSend={props.sendComment}
            />
          </div>
        </Segment>
      </div>
    </Grid.Column>
  );
}

export default Feedback;
