import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import makeSelectGlobal from "../../../redux/redux/global/selectors";

export default function ExitHoc(ComposedComponent) {
  class HOC extends Component {
    state = {
      staffData: [
          {
            
            empCode: 1,
            name: "aishwarya",
            dept: "dept1",
            designation: "des1",
            doj: "08-02-2022",
            dor: "08-02-2022",
            lwd:"08-02-2022",
        },
        {
            empCode: 2,
            name: "akshata",
            dept: "dept1",
            designation: "des1",
            doj: "08-02-2022",
            dor: "08-04-2022",
            lwd:"08-04-2022",
        },
        {
            empCode: 3,
            name: "mukesh",
            dept: "dept1",
            designation: "des1",
            doj: "08-02-2022",
            dor: "08-02-2022",
            lwd:"08-02-2022",
        },
        ],
       
    };
   
    componentDidMount = () => {
    }
    
    render() {
        return (
          <ComposedComponent
            {...this.props}
                staffData={this.state.staffData}
                reason={this.state.reason}
            />
          );
    }
  }
  const mapStateToProps = createStructuredSelector({
    global: makeSelectGlobal(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  return compose(withConnect, memo)(HOC);
}
