import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import Spinners from "../../../components/Spinner/Spinners";
import makeSelectEnquiry from "../../../redux/redux/enquiry/selectors";
import { makeSelectGlobal } from "../../../redux/redux/global/selectors";
import axios from "../../../utils/axios";
import { getCusLocalDateYear, getLocalTime } from "../../../utils/localTime";
import * as qs from "query-string";
import {
  setSource,
  setCategorisation,
  setEngagement,
  setEvent,
  addEnquiryData,
  searchEnquiry,
  searchEnquiryReset,
  setEnquiryCount,
  setLoading,
  setEnquiryPageNumber,
} from "../../../redux/redux/enquiry/action";
import { toast } from "react-toastify";
import {
  closeModalAction,
  openModalAction,
} from "../../../redux/redux/modals/actions";
import { DELETE_CONFIRMATION } from "../../../utils/constant.utils";
import { withRouter } from "react-router";
// import { setAssignsTo, setCompanies } from "../../redux/redux/addEnquiry/action";
// import makeSelectAddEnquiry from "../../redux/redux/addEnquiry/selectors";

export default function EnquiryListHoc(ComposedComponent) {
  // enquiry HOC component NOT in use
  class HOC extends Component {
    state = {
      loading: false,
      mounted: false,
    };
    onSelect = (value, type) => {
      this.props.dispatch(searchEnquiry(type, value));
    };
    resetSearch = () => {
      this.props.dispatch(searchEnquiryReset());
    };
    getData = async (params = {}) => {
      await this.props.dispatch(setLoading(true));
      axios
        .get("/marketing/enquiry/fetchAllEnquiry", {
          params: {
            offset: this.props.enquiry.pageNumber * 10 - 10,
            limit: 10,
            ...params,
          },
        })
        .then(async (result) => {
          let array = (await result.data.result.rows) || [];
          const count = (await result.data.result.count) || 0;
          array = array.map((item) => {
            item.category = item?.Enquiry_Category?.name || "N/A";
            item.status = item?.Enquiry_SLA_Status?.name || "-";
            item.source = item?.Enquiry_Source?.name || "N/A";
            item.engagement = item.Enquiry_engagement.name || "N/A";
            item.event = item.Enquiry_event.event_name || "N/A";
            return item;
          });
          this.props.dispatch(addEnquiryData([...array]));
          this.props.dispatch(setEnquiryCount(count));
        })
        .catch(async (error) => {
          this.props.dispatch(addEnquiryData([]));
          this.props.dispatch(setEnquiryCount(0));
        })
        .finally(() => {
          this.props.dispatch(setLoading(false));
          this.setState({ loading: false });
        });
    };
    getEvent = () => {
      axios
        .get("/dds/listEnquirySlaStatus")
        .then((res) => {
          let data = [...res.data.result.rows];
          // data.ma
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setEvent(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    getSource = () => {
      axios
        .get("/dds/listEnquirySource")
        .then((res) => {
          let data = [...res.data.result.rows];
          // data.ma
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setSource(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    getEngagement = () => {
      axios
        .get("/dds/listEnquiryEngagement")
        .then((res) => {
          let data = [...res.data.result.rows];
          // data.ma
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setEngagement(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    getCategorisation = () => {
      axios
        .get("/dds/listEnquiryCategory")
        .then((res) => {
          let data = [...res.data.result.rows];
          // data.ma
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setCategorisation(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    setEnquiryPageNumber = async (pageNumber) => {
      await this.props.dispatch(setEnquiryPageNumber(pageNumber));
      const query = await qs.stringify({ p: pageNumber });
      await this.props.history.push(this.props.location.pathname + `?${query}`);
      // await this.getData();
    };

    editRoute = (id) => {
      this.props.history.push(`/addEnquiry/update/${id}`);
    };

    deleteAction = (value, id) => {
      this.props.dispatch(closeModalAction());
      if (value === true) {
        this.deleteEnquiry(id);
      }
    };
    deleteEnquiry = (id) => {
      axios
        .delete(`/marketing/enquiry/deleteEnquiry/${id}`)
        .then((res) => {
          if (res.status === 200)
          toast.success(`Enquiry Deleted with Enquiry Id: ${id}`, {
            toastId: "EnquiryDeletedSuccessfully",
          });
        })
        .catch((e) => {})
        .finally(() => {
          this.getData();
        });
    };

    openDeleteAction = (id) => {
      this.props.dispatch(
        openModalAction({
          modalType: "DeleteAction",
          modalProps: {
            ...DELETE_CONFIRMATION,
            deleteFn: (value) => this.deleteAction(value, id),
            title: "Delete Enquiry",
            message: "Are you sure you want to delete this Enquiry?",
          },
        })
      );
    };
    renderLoading = () => {
      if (this.state.loading) return <Spinners />;
      return (
        <ComposedComponent
          {...this.props}
          setEnquiryPageNumber={this.setEnquiryPageNumber}
          deleteEnquiry={this.openDeleteAction}
          editRoute={this.editRoute}
          onSelect={this.onSelect}
          getData={this.getData}
        />
      );
    };
    componentDidMount() {
      this.getEvent();
      this.getSource();
      this.getEngagement();
      this.getCategorisation();
    }
    render() {
      return <>{this.renderLoading()}</>;
    }
  }
  const mapStateToProps = createStructuredSelector({
    enquiry: makeSelectEnquiry(),
    global: makeSelectGlobal(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(withConnect, memo, withRouter)(HOC);
}
