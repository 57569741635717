import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Menu, Select } from "semantic-ui-react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import CustomTable from "../../../components/CustomTable/CustomTable";
import StaffTrainingHoc from "./staffTrainingHoc";
import axios from "../../../utils/axios";
import { store } from "../../../redux/store";
import { twoDecimal } from "../../../utils/randomNumber";
import moment from "moment";
import AccessCheck from "../../../utils/AccessCheck";
import { accessActionType, accessModule } from "../../../utils/accessConstant";
import { dateFormat, EDIT, ID, SR } from "../../../utils/labelContant";
import { capital} from "../../../utils/lodash";

const StaffTraining = (props) => {
  const [data, setData] = useState();
  const [data1, setdata1] = useState();
  const [staffData, setStaffData] = useState();
  const [staffValue, setStaffValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [pageNumber1, setPageNumber1] = useState(1);
  const [countStaff, setCountStaff] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    training_topic: "",
    trainer: "",
  });
  let userData = store.getState().global;
  const onSelect = (value, type) => {
    setSearch({
      ...search,
      [type]: value,
    });
  };
  const clickFunction = () => {
    props.history.push("addTraining/create");
  };
  // Fetch All staff through API
  const fetchStaff = () => {
    let result = [];
    axios
      .get("/staff/fetchAllDotStaff")
      .then((res) => {
        result = [...res.data.result.rows];
        let filterDoc1 = result.filter((e) => e.role !== 3);
        result = filterDoc1.map((item, index) => {
          return {
            text: item.employee_name,
            value: item.employee_name,
            value: item.id + "-" + item.role + "-" + item.employee_name,
          };
        });
        setStaffData([...result]);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  // get list of training from API call for Admin
  const getData = () => {
    let temp = staffValue.split("-");
    axios
    .get(`staff/training/fetchAllTrainings`, {
        params: {
          offset: pageNumber * 10 - 10,
          limit: 10,
          year: props.selectedYear,
          trainee_name: temp[2],
          training_topic: search.training_topic,
        },
      })
      .then(async (res) => {
        let data = res.data.result[1].training_details;
        data = data.map((value) => {
          let newVal = {
            ...value,
            id: value.id,
            date_training: moment(value.date_of_training, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            ),
            training_topic: value.training_topic,
            duration: value.duration,
            details: (
              <p
                className="link-text"
                onClick={() =>
                  props.history.push({
                    pathname: "addTraining/view",
                    viewData: value,
                  })
                }
              >
                View
              </p>
            ),
          };
          return newVal;
        });
        let count1 = 0;
        data = data.map((value) => {
          count1 = count1 + value.duration;
          return value;
        });
        data.push({
          id: <div className="bg-white">{""}</div>,
          date_training: "",
          training_topic: "",
          duration: twoDecimal(count1),
          details: "",
        });
        setData([...data]);
        setCount(res.data.result[0].total_count);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  // get list of Training for staff and manager
  const getStaffData = () => {
    let temp = staffValue.split("-");
    axios
      .get(`/staff/training/fetchAllTrainingsEmployee`, {
        params: {
          offset: pageNumber1 * 10 - 10,
          limit: 10,
          dot_trainer_name: search.trainer,
          training_topic: search.training_topic,
        },
      })
      .then(async (res) => {
        let data = res?.data?.result[1]?.training_details;
        data = data.map((value) => {
          let newVal = {
            ...value,
            id: value?.id,
            date_training: dateFormat(value?.date_of_training),
            training_topic: value?.training_topic,
            duration: value?.duration,
            trainer: value?.dot_trainer_name,
            details: (
              <p
                className="link-text"
                onClick={() =>
                  props.history.push({
                    pathname: "addTraining/view",
                    viewData: value,
                  })
                }
              >
                View
              </p>
            ),
          };
          return newVal;
        });
        let count = 0;
        data = data.map((value) => {
          count = count + value.duration;
          return value;
        });
        data.push({
          id_sr: "",
          date_training: "",
          training_topic: "",
          duration: count,
          trainer: "",
          details: "",
        });
        setdata1([...data]);
        setCountStaff(data.length);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  useEffect(() => {
    fetchStaff();
    getData();
    getStaffData();
  }, [props.selectedYear, staffValue, search, pageNumber, pageNumber1]);

  return (
    <>
      <Helmet>
        <title>Templates</title>
        <meta name="description" content="Templates Listing" />
      </Helmet>
      <div className="container-height-width">
        <div className="container-height-width ">
          <TitleWrapper>
            <ContainerTitle title={"Training"} />
            {props?.location?.pathname === "/training-list" &&
              AccessCheck(
              accessModule.STAFF_TRAINING_CREATE_TRAINING,
              accessActionType.CREATE,
              store.getState().global?.access
            ) && (
              <div>
                <Button
                  className="dotButton"
                  floated="right"
                  content="Add New Training"
                  color="green"
                  onClick={() => clickFunction()}
                />
              </div>
            )}
          </TitleWrapper>
        </div>
        <div className="tableWrapper">
          {props?.location?.pathname === "/training-list" &&
          AccessCheck(
            accessModule.STAFF_TRAINING_CREATE_TRAINING,
            accessActionType.CREATE,
            store.getState().global?.access
          ) ? (
            <Form>
              <Form.Group widths="equal" className="formGroup" width={16}>
                <Form.Field
                  control={Select}
                  label="YEAR"
                  clearable
                  upward={false}
                  className="topMarginInput inlineLabel dropIndex"
                  options={props.yearOptions}
                  placeholder="Year"
                  selectOnBlur={false}
                  search
                  width={8}
                  name="year"
                  value={props?.selectedYear}
                  onChange={(e, { value }) => {
                    props?.setSelectedYear(value);
                  }}
                />
              </Form.Group>
            </Form>
          ) : (
            ""
          )}
          {/* </div> */}
          <div className="trainingTable">
            {props?.location?.pathname === "/training-list" &&         
             AccessCheck(
              accessModule.STAFF_TRAINING_CREATE_TRAINING,
              accessActionType.READ,
              store.getState().global?.access
            ) 
              ? (
              <CustomTable
                cols={[
                  {
                    id: "id",
                    title: "Sr.No",
                  },
                  {
                    id: "date_training",
                    title: "Date of Training",
                  },
                  {
                    id: "training_topic",
                    title: "Training Topic",
                    search: true,
                    onSelect: onSelect,
                    type: "training_topic"
                  },
                  {
                    id: "duration",
                    title: "Duration",
                  },
                  {
                    id: "details",
                    title: "Details",
                  }
                ]}
                data={data}
                count={count}
                setPagination={(n) => setPageNumber(n)}
                activePage={pageNumber}
                loading={loading}
                renderMenuItem={(row) => {
                  return (
                    <Menu fluid vertical tabular className="user-menu">
                      {AccessCheck(
                        accessModule.STAFF_TRAINING_CREATE_TRAINING,
                        accessActionType.UPDATE,
                        store.getState().global?.access
                      ) && (
                        <Menu.Item
                          name="edit"
                          onClick={() =>
                            props.history.push({
                              pathname: "addTraining/update",
                              updateData: row,
                            })
                          }
                        >
                          {capital(EDIT)}
                        </Menu.Item>
                      )}
                    </Menu>
                  );
                }}
              />
            ) : (
              ""
            )}
            {userData.userDetails.role !== 3 &&
            props?.location?.pathname === "/view-training" ? (
              <CustomTable
                cols={[
                  {
                    id: ID,
                    title: SR,
                  },
                  {
                    id: "date_training",
                    title: "Date of Training",
                  },
                  {
                    id: "training_topic",
                    title: "Training Topic",
                    search: true,
                    onSelect: onSelect,
                    type: "training_topic"
                  },
                  {
                    id: "trainer",
                    title: "Trainer",
                    search: true,
                    onSelect: onSelect,
                    type: "trainer"
                  },
                  {
                    id: "duration",
                    title: "Duration",
                  },
                ]}
                data={data1}
                count={countStaff}
                setPagination={(n) => setPageNumber1(n)}
                activePage={pageNumber1}
                error={(e) => {}}
                loading={loading}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default StaffTrainingHoc(StaffTraining);