import produce from "immer";
import {
  ADD_ASSIGNS,
  ADD_TYPE,
  ADD_COMPANIES,
  ADD_CURRENCY,
  ADD_VAT,
  ADD_ROLES,
  RESET_COMPANIES,
  RESET_CURRENCY,
  RESET_VAT,
  RESET_ASSIGNS,
  RESET_TYPE,
  RESET_ROLES,
  ADD_FULL_PROFILE,
  RESET_FULL_PROFILE,
  ADD_FULL_CONTACT,
  RESET_FULL_CONTACT,
} from "./constants";
export const INITIAL_STATE = {
  fullprofile: "",
  fullcontact: [],
  companies: [],
  currency: [],
  vat: [],
  roles: [],
  assigns: [],
  type: [],
};
const addClientReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_COMPANIES:
        draft.companies = [...action.companies];
        break
      case RESET_COMPANIES:
        draft.companies = [];
        break
      case ADD_CURRENCY:
        draft.currency = [...action.currency];
        break
      case RESET_CURRENCY:
        draft.currency = [];
        break
      case ADD_VAT:
        draft.vat = [...action.vat];
        break
      case RESET_VAT:
        draft.vat = [];
        break
      case ADD_ROLES:
        draft.roles = [...action.roles];
        break
      case RESET_ROLES:
        draft.roles = [];
        break
      case ADD_ASSIGNS:
        draft.assigns = [...action.assigns];
        break;
      case RESET_ASSIGNS:
        draft.assigns = [];
        break;
      case ADD_TYPE:
        draft.type = [...action.type];
        break;
      case RESET_TYPE:
        draft.type = [];
        break;
      case ADD_FULL_PROFILE:
        draft.fullprofile = { ...action.fullprofile };
        break;
      case RESET_FULL_PROFILE:
        draft.fullprofile = "";
        break;
      case ADD_FULL_CONTACT:
        draft.fullcontact = [ ...action.fullcontact ];
        break;
      case RESET_FULL_CONTACT:
        draft.fullcontact = "";
        break;
      default:
        return draft;
    }
  });
};

export default addClientReducer;
