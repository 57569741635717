import React, { useEffect, useState,useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Button, Icon } from "semantic-ui-react";
import ContainerTitle from "../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../components/ContainerTitle/TitleWrapper";
import CustomTable from "../../components/CustomTable/CustomTable";
import axios from "../../utils/axios";
import encryptData from "../../utils/crypto";
import {
  ACTUAL,
  ARCHIE_JOB_EXPORT,
  ArchiveHeader,
  ARCHIVE_JOBS,
  BUDGET,
  BUDGET_ID,
  CLIENT,
  CLIENT_NAME,
  CNAME,
  COMPANY,
  COMPANY_NAME,
  COMPANY_NAMES,
  COMPLETED,
  CREATED_BY,
  CREATE_BY,
  DAYS,
  DAYS_LEFT,
  Day_Left,
  DEADLINE_DATE,
  DISPATCH,
  DISPATCH_DATE_ID,
  EMAIL,
  EXPORT,
  ID,
  JOBID,
  JOB_CREATION_DATE,
  JOB_END_DATE,
  JOB_MANAGER_DOT,
  JOB_REFERENCE_NO,
  JOB_START_DATE,
  JOB_STATUS_ID,
  JOB_STATUS_PERC,
  JOB_TAT,
  JOB_TITLE_ID,
  JOB_TYPE_ID,
  JOB_TYPE_TITLE,
  MANAGER,
  NOT_APPLICABLE,
  NOT_APPLICABLE_DATE,
  OVERDUE,
  REFERENCE_NO,
  REF_NO,
  RESET,
  STATUS_ID,
  STATUS_TITLE,
  TAT,
  YEAREND_DATE,
  YEAR_END_DATE,
} from "../../utils/labelContant";
import { getCusLocalDate, getCusLocalDateYear } from "../../utils/localTime";
import { lower_Case } from "../../utils/lodash";
import { CSVLink } from "react-csv";
import ArchiveHoc from "./ArchiveHoc";
import moment from "moment";
import AccessCheck from "../../utils/AccessCheck";
import { store } from "../../redux/store";
import { loopObject } from "../../utils/objectLoop";
import { percentAsPerStatus } from "../../utils/percAsPerStatus";
import {getExportFile } from "../../utils/presignedUpload";

const Archive = ({
  archive,
  onSelect,
  resetSearch,
  setPageNumber,
  getData,
  ...props }) => {
  const [exportData, setExportData] = useState("");
  
  const [showReset, setShowReset] = useState(false);
  const history = useHistory();
  const getExportData = async () => {
    try {
      const response = await axios.get('jobs/fetchArchiveJob', {
        params: {
          ...archive.archiveSearch,
          limit: "",
          offset: ""
        }
      });
      const result = response.data.result;
      console.log(result);
      setExportData(result);
    } catch (error) {
      console.log(error.message);
    }
  } 
  const checkSearch = useCallback(() => {
    let search = false;
    Object.values({ ...archive.archiveSearch }).forEach((value) => {
      if (value) {
        search = true; 
      }
    });
    return search;
  }, [archive.archiveSearch]);

  const getArchiveList = useCallback(
    (searchObject) => {
      getData({ ...searchObject });
    }, [getData]);
  
  useEffect(async() => { 
    getArchiveList(loopObject(archive.archiveSearch));
    getExportData();
    setShowReset(checkSearch());
  }, [ archive.pageNumber, archive.archiveSearch, getArchiveList, checkSearch ]);

  return (
    <div className="container-height-width">
      <TitleWrapper>
        <ContainerTitle title={ARCHIVE_JOBS} />
        <div>
        {showReset &&(
              <Button
                className="dotButton"
                basic
                floated="right"
                content={RESET}
                color="red"
                onClick={resetSearch}
              />
            )}
            <Button
              icon
              labelPosition="right"
              basic
              color="red"
              loading={exportData.length === 0}
              onClick={async() => {await getExportFile(exportData)}}
              className={
               "dotButton"
              }
            >
              {EXPORT}
              <Icon name="file excel" />
            </Button>
          {/* </CSVLink> */}
        </div>
      </TitleWrapper>

      <div className="customTableWraper">
        <CustomTable
          cols={[
            {
              id: ID,
              title: JOB_TITLE_ID,
              classStyle: {},
              fixed: true,
              search: true,
              onSelect: onSelect,
              type: JOBID,
              className: "first-col",
              text: archive?.archiveSearch?.jobId || "",
            },
            {
              id: REFERENCE_NO,
              title: JOB_REFERENCE_NO,
              search: true,
              onSelect: onSelect,
              type: REF_NO,
              text:archive?.archiveSearch?.refNo || "",
            },
            {
              id: COMPANY,
              title: COMPANY_NAME,
              classStyle: {},
              search: true,
              onSelect: onSelect,
              type: CNAME,
              text : archive?.archiveSearch?.cname || ""
            },
            {
              id: CLIENT.toLowerCase(),
              title: CLIENT_NAME,
              classStyle: {},
              onSelect: onSelect,
              search: true,
              type: COMPANY_NAMES,
              text : archive?.archiveSearch?.company_name || ""
            },
            {
              id: JOB_STATUS_PERC,
              title: COMPLETED,
              classStyle: {},
            },
            {
              id: JOB_TYPE_ID,
              title: JOB_TYPE_TITLE,
              classStyle: {},
              search: false,
            },
            {
              id: YEAREND_DATE,
              title: YEAR_END_DATE,
              classStyle: {},
              search: false,
              type: EMAIL,
            },
            {
              id: JOB_STATUS_ID,
              title: STATUS_TITLE,
              classStyle: {},
              search: true,
              onSelect: onSelect,
              options: [...props.jobStatus],
              text: archive?.archiveSearch?.status || "",
              type: STATUS_ID,
            },
            {
              id: BUDGET_ID,
              title: BUDGET,
              classStyle: {},
              search: false,
              onSelect: onSelect,
              type: BUDGET_ID,
            },
            {
              id: lower_Case(ACTUAL),
              title: ACTUAL,
              classStyle: {},
              search: false,
              onSelect: onSelect,
              type: lower_Case(ACTUAL),
            },
            {
              id: CREATED_BY,
              title: CREATE_BY,
            },
            {
              id: JOB_START_DATE,
              title: JOB_CREATION_DATE,
            },
            {
              id: JOB_END_DATE,
              title: DEADLINE_DATE,
            },
            {
              id: DISPATCH,
              title: DISPATCH_DATE_ID,
            },
            {
              id: JOB_TAT,
              title: TAT,
            },
            {
              id: DAYS_LEFT,
              title: Day_Left,
            },
            {
              id: MANAGER,
              title: JOB_MANAGER_DOT,
            },
          ]}
          data={archive?.archive}
          count={archive?.count}
          setPagination={(pageNumber) => setPageNumber(pageNumber)}
          activePage={archive?.pageNumber}
          renderMenuItem={(row) => {
            return (
              <Menu fluid vertical tabular className="user-menu">
                  <Menu.Item
                    name="Details"
                    onClick={() =>
                      history.push(
                        `/jobDetails/${encryptData(row.id.toString(), "enc")}`
                      )
                    }
                  />
              </Menu>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ArchiveHoc(Archive);
