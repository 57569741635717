import React, { useState } from "react";

import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Form, Grid, Select } from "semantic-ui-react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { makeSelectDeviceDimension } from "../../../redux/redux/device/selectors";
import {
  resetAddCountry,
  setAddCountry,
} from "../../../redux/redux/masters/action";
import makeSelectMaster from "../../../redux/redux/masters/selectors";
import { closeModalAction } from "../../../redux/redux/modals/actions";
function AddCountry(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <ModalWrapper
      size="mini"
      header={props.header || "Add Country Activity"}
      esc
      scrolling
      className={"add"}
      closeModal={props.closeModal}
    >
      <Form.Group className="">
        <Form.Input
          className=""
          label="Country Name"
          placeholder="Country Name"
          // width={16}
          name="Country_name"
          value={props.master.addCountry.Country_name}
          onChange={(e) => dispatch(setAddCountry(e))}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100% !important",
          }}
        />
        <Form.Input
          className=""
          label="Country Code"
          placeholder="Country Code"
          // width={16}
          name="Country_code"
          value={props.master.addCountry.Country_code}
          onChange={(e) => dispatch(setAddCountry(e))}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100% !important",
          }}
        />
      </Form.Group>

      <Grid
        columns="2"
        centered
        verticalAlign="middle"
        style={{ marginTop: "20px" }}
      >
        <Grid.Column>
          <Button
            style={{ width: "100%" }}
            className="secondary"
            onClick={() => {
              setLoading(true);
              props.onSubmit(props.master.addCountry);
            }}
            loading={loading}
            disabled={loading}
          >
            {props.type && props.type === "edit" ? "UPDATE" : "ADD"}
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="red"
            style={{ width: "100%" }}
            onClick={() => {
              dispatch(resetAddCountry());
              dispatch(closeModalAction());
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  dimension: makeSelectDeviceDimension(),
  master: makeSelectMaster(),
});

export default connect(mapStateToProps, null)(AddCountry);
